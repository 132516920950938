import React from 'react';

import {
  requestBrands,
  requestCategories,
  requestEvents,
  requestLocations,
  requestMerchants,
  requestTags,
} from '@ttstr/actions';
import {
  IndexedBrands,
  IndexedCategories,
  IndexedEvents,
  IndexedLocations,
  IndexedMerchants,
  IndexedTags,
} from '@ttstr/api/products';
import { AppState } from '@ttstr/reducers';
import { getBrands, getBrandsReceived } from '@ttstr/reducers/brands';
import { getCategories, getCategoriesReceived } from '@ttstr/reducers/categories';
import { getEvents, getEventsReceived } from '@ttstr/reducers/events';
import { getLocations, getLocationsReceived } from '@ttstr/reducers/locations';
import { getMerchants, getMerchantsReceived } from '@ttstr/reducers/merchants';
import { getTags, getTagsReceived } from '@ttstr/reducers/tags';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

interface OwnProps {
  loading?: boolean;
}

interface StateProps {
  brands?: IndexedBrands;
  brandsReceived?: boolean;
  categories?: IndexedCategories;
  categoriesReceived?: boolean;
  events?: IndexedEvents;
  eventsReceived?: boolean;
  locations?: IndexedLocations;
  locationsReceived?: boolean;
  merchants?: IndexedMerchants;
  merchantsReceived?: boolean;
  tags?: IndexedTags;
  tagsReceived?: boolean;
}

export type ProductDetails = Readonly<OwnProps & StateProps>;

type ContextProps = Readonly<React.PropsWithChildren<OwnProps>>;

export const ProductDetailsContext = React.createContext<ProductDetails>({});

export const { Consumer: ProductDetailsConsumer } = ProductDetailsContext;

const ProductDetailsProviderComponent: React.FC<ContextProps> = ({ children }) => {
  const {
    brands,
    brandsReceived,
    categories,
    categoriesReceived,
    events,
    eventsReceived,
    locations,
    locationsReceived,
    merchants,
    merchantsReceived,
    tags,
    tagsReceived,
  } = useShallowEqualSelector(mapStateToProps);
  const {
    requestBrands,
    requestCategories,
    requestEvents,
    requestLocations,
    requestMerchants,
    requestTags,
  } = useActions(mapDispatchToProps);

  React.useEffect(() => {
    requestBrands();
    requestCategories();
    requestEvents();
    requestLocations();
    requestMerchants();
    requestTags();
  }, []);

  const productDetails = React.useMemo(() => {
    const loading = !(
      brandsReceived &&
      categoriesReceived &&
      eventsReceived &&
      locationsReceived &&
      merchantsReceived &&
      tagsReceived
    );
    return {
      brands,
      brandsReceived,
      categories,
      categoriesReceived,
      events,
      eventsReceived,
      loading,
      locations,
      locationsReceived,
      merchants,
      merchantsReceived,
      tags,
      tagsReceived,
    };
  }, [
    brands,
    brandsReceived,
    categories,
    categoriesReceived,
    events,
    eventsReceived,
    locations,
    locationsReceived,
    merchants,
    merchantsReceived,
    tags,
    tagsReceived,
  ]);
  return <ProductDetailsContext.Provider value={productDetails}>{children}</ProductDetailsContext.Provider>;
};

export const ProductDetailsProvider = React.memo(ProductDetailsProviderComponent);

export const useProductDetails = () => React.useContext(ProductDetailsContext);

const mapStateToProps = (state: AppState) => {
  return {
    brandsReceived: getBrandsReceived(state),
    categoriesReceived: getCategoriesReceived(state),
    eventsReceived: getEventsReceived(state),
    locationsReceived: getLocationsReceived(state),
    merchantsReceived: getMerchantsReceived(state),
    tagsReceived: getTagsReceived(state),
    brands: getBrands(state),
    categories: getCategories(state),
    events: getEvents(state),
    locations: getLocations(state),
    merchants: getMerchants(state),
    tags: getTags(state),
  };
};

const mapDispatchToProps = {
  requestBrands,
  requestCategories,
  requestEvents,
  requestLocations,
  requestMerchants,
  requestTags,
};
