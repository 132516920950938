import React from 'react';

import { useTranslation } from 'react-i18next';

import Card from 'reactstrap/lib/Card';

import { Products } from '@ttstr/api';
import { receiveCartOptions } from '@ttstr/actions';
import { useActions } from '@ttstr/utils';

import { VariantChooser, LoadingSpinner, ContentrArea } from '@ttstr/components';
import { MediaVariantChooserForm } from '@ttstr/components/VariantListing/VariantChooser';

const CartOptions: React.FC = () => {
  const { t } = useTranslation();
  const [products, setProducts] = React.useState<Products>([]);
  const [loading, setLoading] = React.useState(false);
  const { receiveCartOptions } = useActions(mapDispatchToProps);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const cartOptions = await receiveCartOptions();
        setProducts(cartOptions);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) return <LoadingSpinner label={t('LOADING.PRODUCT_OPTIONS')} />;

  //* If there are no products that aren't sold out, don't display options at all *//
  if (!products?.filter((p) => !Boolean(p.sold_out_status)).length) return null;

  return (
    <>
      <hr />
      <Card body className="card-shadow product-variant-listing mt-3 cart-option-item">
        <h5>{t('CART.OPTIONS.TITLE')}</h5>
        <p>{t('CART.OPTIONS.DESCRIPTION')}</p>
        <ContentrArea id="above-cart-options" />
        {products
          .filter((p) => !Boolean(p.sold_out_status))
          .filter((p) => Object.keys(p.online_variants_attributes).length)
          .map((product) => (
            <VariantChooser variantChooserForm={MediaVariantChooserForm} key={product.id} product={product} />
          ))}
      </Card>
    </>
  );
};

const mapDispatchToProps = {
  receiveCartOptions,
};

export default React.memo(CartOptions);
