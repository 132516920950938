import React from 'react';
import { Link } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Button from 'reactstrap/lib/Button';
import Container from 'reactstrap/lib/Container';

interface Props {
  readonly showH1?: boolean;
}

const NotFoundView: React.FC<Props> = ({ showH1 = false }) => {
  const { t } = useTranslation();

  return (
    <article className="my-5">
      <Helmet>
        <title>{t(`NOT_FOUND.TITLE`)}</title>
        <meta name="robots" content="noindex" />
        <body className="page notFound-page" />
      </Helmet>

      <Container className="text-center">
        {showH1 && <h1 className="text-center mb-4">{t(`NOT_FOUND.TITLE`)}</h1>}
        <p className="emoji-thumb">
          <span role="img" lang="en" aria-label="screaming cat emoji">
            {'🙀'}
          </span>
        </p>
        <p>{t(`NOT_FOUND.LEAD`)}</p>

        <Button tag={Link} color="primary" to="/">
          {t('CART.EXPIRED_OR_EMPTY.START_SHOPPING')}
        </Button>
      </Container>
    </article>
  );
};

export default React.memo(NotFoundView);
