import classNames from 'classnames';

import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from 'reactstrap/lib/Button';
import Spinner from 'reactstrap/lib/Spinner';

import { addToCart, receiveProduct } from '@ttstr/actions';
import { Product, Variant } from '@ttstr/api/products';
import { useActions, useToggle } from '@ttstr/utils';

import NumberInput from '../Input/NumberInput';
import Currency from '../Intl/Currency';
import PackageModal from './PackageModal';
import { VariantInformation } from './VariantChooser';

interface OwnProps {
  product: Product;
  variant: Variant;
  addToCartTrackingHook?: (v: Variant, q: number) => void | null;
}

type Props = Readonly<OwnProps>;

const VariantColumn: React.FC<Props> = ({ variant, product, addToCartTrackingHook }) => {
  const { t } = useTranslation();

  const defaultValues = React.useMemo<VariantInformation>(
    () => ({
      variantId: String(variant.id),
      quantity: variant.remaining_for_online > 1 ? String(variant.min_per_order) : '0',
    }),
    [variant]
  );

  const formContext = useForm<VariantInformation>({
    mode: 'onChange',
    defaultValues,
  });
  const { register, getValues, reset, watch } = formContext;
  const [busy, setBusy] = React.useState(false);
  const [showPackageModal, togglePackageModal] = useToggle(false);
  const [abortController, setAbortController] = React.useState<AbortController>();
  const { addToCart, receiveProduct } = useActions(mapDispatchToProps);

  const selectedQuantity = watch('quantity');

  React.useEffect(() => {
    setAbortController(new AbortController());
    () => abortController.abort();
  }, []);

  const minPerOrder = React.useMemo(() => variant?.min_per_order ?? 1, [variant]);
  const remainingForOnline = React.useMemo(() => variant?.remaining_for_online ?? 0, [variant]);
  const isPackage = Boolean(variant.package_membership_groups_attributes);

  const handleAddToCartClick = async (/* e: React.MouseEvent<HTMLButtonElement> */) => {
    if (variant?.package_membership_groups_attributes) {
      // Cancel submission and require more package-related information
      togglePackageModal();
      return;
    }
    try {
      setBusy(true);
      await addToCart(Number(variant.id), Number(getValues().quantity));
      if (addToCartTrackingHook) addToCartTrackingHook(variant, Number(getValues().quantity));

      await receiveProduct(Number(product.id)); // Update product availability afterwards
      reset();
    } finally {
      setBusy(false);
    }
  };

  if (variant.available_end_at && variant.available_end_at <= new Date()) return null;
  if (variant.remaining_for_online < 0) return null;

  const buttonLabel = !variant.remaining_for_online
    ? variant.stock_message || t('PRODUCT.SOLD_OUT_STATUS.sold_out')
    : t(isPackage ? 'PRODUCT.CONFIGURE_PACKAGE.TITLE' : 'PRODUCT.ADD_TO_CART', {
        count: Number(selectedQuantity),
      });

  return (
    <FormProvider {...formContext}>
      <tr itemScope itemType="http://schema.org/ProductModel" itemID={`${variant.id}`}>
        <th
          scope="row"
          title={variant.title || product.title}
          itemProp="name"
          className={classNames(!variant.remaining_for_online && 'text-muted')}
        >
          {variant.title || product.title}
          <input type="hidden" name="variantId" ref={register} />
        </th>
        <td className="text-nowrap text-right">
          <Currency value={variant.total_price} />
        </td>
        <td className="text-nowrap">
          {variant.external_label && variant.external_url ? (
            <span>{variant.external_label}</span>
          ) : (
            <NumberInput
              name="quantity"
              color="secondary"
              outline={false}
              min={minPerOrder}
              max={remainingForOnline}
              disabled={busy || !variant.remaining_for_online}
              innerRef={register}
            />
          )}
        </td>
        <td className="text-nowrap">
          {variant.external_label && variant.external_url ? (
            <a href={variant.external_url} target="blank" rel="noopener noreferrer">
              <Button
                type="button"
                className="add-product external-product text-nowrap btn-primary"
                color="primary"
                // tag="a"
                // href={variant.external_url}
                // target="blank"
                // rel="noopener noreferrer"
              >
                <i className="fal fa-arrow-right" />
                <span className="ml-2 d-none d-md-inline d-lg-none">{variant.external_label}</span>
              </Button>
            </a>
          ) : (
            <Button
              type="button"
              className="add-product text-nowrap"
              disabled={busy || !variant.remaining_for_online}
              color="primary"
              onClick={handleAddToCartClick}
              title={buttonLabel}
            >
              <i className="fal fa-shopping-bag" />
              <span className="ml-2 d-none d-md-inline d-lg-none">{buttonLabel}</span>
            </Button>
          )}
          <Spinner color="primary" size="sm" className={busy ? 'visible' : 'invisible'} />{' '}
          <PackageModal
            product={product}
            // cartIcon={cartIcon}
            isOpen={showPackageModal}
            toggle={togglePackageModal}
            reset={reset}
          />
        </td>
      </tr>
    </FormProvider>
  );
};

const mapDispatchToProps = {
  addToCart,
  receiveProduct,
};

export default React.memo(VariantColumn);
