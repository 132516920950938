import classNames from 'classnames';

import React from 'react';

interface OwnProps {
  animation?: 'fade' | 'zoom';
  speed?: 'slow';
  from?: 'left' | 'right' | 'top' | 'bottom';
  className?: string;
  revealClassName?: string;
  options?: IntersectionObserverInit;
}

type Props = Readonly<React.PropsWithChildren<OwnProps>>;

const Reveal: React.FC<Props> = ({
  animation = 'fade',
  speed,
  from,
  className,
  revealClassName = 'show',
  options,
  children,
}) => {
  const intersectionRef = React.useRef<HTMLDivElement>();
  const [revealed, setRevealed] = React.useState(false);

  React.useEffect(() => {
    if (!window.IntersectionObserver) return setRevealed(true);
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setRevealed(true);
          observer.disconnect();
        }
      });
    }, options);
    observer.observe(intersectionRef.current);

    return () => observer.disconnect();
  }, [options]);

  return (
    <div
      ref={intersectionRef}
      className={classNames(
        className,
        animation,
        speed && `${animation}-speed-${speed}`,
        from && `${animation}-from-${from}`,
        { [revealClassName]: revealed }
      )}
    >
      {children}
    </div>
  );
};

export default Reveal;
