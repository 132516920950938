import React from 'react';

import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

import Alert from 'reactstrap/lib/Alert';
import Container from 'reactstrap/lib/Container';

import { requestCustomer } from '@ttstr/actions/customer';
import { BackendError, G8Error } from '@ttstr/api';
import { requestActiveHeartBeat } from '@ttstr/api/heartbeat';
import { AppState } from '@ttstr/reducers';
import { ReduxState as AuthState } from '@ttstr/reducers/customer';
import { useActions, useMounted, useShallowEqualSelector } from '@ttstr/utils';
import { LoadingSpinner } from '..';

export interface AuthContextProps extends AuthState {
  redirectAfterLogin: boolean;
  setShowLogin(show: boolean, redirect?: boolean): void;
  showLogin: boolean;
}

const initialState: AuthContextProps = {
  customer: null,
  error: null,
  initialized: false,
  loggedIn: false,
  loggingIn: false,
  loggingOut: false,
  redirectAfterLogin: true,
  setShowLogin: () => null,
  showLogin: false,
};

const AuthContext = React.createContext<AuthContextProps>(initialState);

export const { Consumer: AuthContextConsumer } = AuthContext;

const mapStateToProps = (state: AppState) => {
  return state.Customer;
};

const mapDispatchToProps = {
  requestCustomer,
};

const AuthProviderComponent: React.FC<React.PropsWithChildren<Record<string, unknown>>> = ({ children }) => {
  const { t } = useTranslation();
  const isMounted = useMounted();
  const authState = useShallowEqualSelector(mapStateToProps);
  const { requestCustomer } = useActions(mapDispatchToProps);
  const history = useHistory();

  const [noConnection, setNoConnection] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState(false);
  const [redirectAfterLogin, setRedirectAfterLogin] = React.useState(true);
  const [heartBeatTimeout, setHeartBeatTimeout] = React.useState<NodeJS.Timeout>(null);

  const startActiveHeartBeat = React.useCallback(async () => {
    clearTimeout(heartBeatTimeout);
    try {
      const beat = await requestActiveHeartBeat();
      if (isMounted.current && beat.valid) setHeartBeatTimeout(setTimeout(startActiveHeartBeat, beat.active_heartbeat));
      else {
        if (!window.location.pathname.startsWith('/orders')) {
          history.replace('/g8');
        }
      }
    } catch (e) {
      console?.error(`Heartbeat could not be executed: ${e.message}`);
    }
  }, [setHeartBeatTimeout, history]);

  const contextState: AuthContextProps = React.useMemo(() => {
    return {
      ...authState,
      redirectAfterLogin,
      setShowLogin(show, redirect) {
        setShowLogin(show);
        setRedirectAfterLogin(!show || redirect !== false);
      },
      showLogin,
    };
  }, [authState, redirectAfterLogin, setShowLogin, setRedirectAfterLogin, showLogin]);

  // onComponentDidMount
  React.useEffect(() => {
    (async () => {
      try {
        await requestCustomer();
        if (isMounted.current) await startActiveHeartBeat();
      } catch (error) {
        if (!isMounted.current) return;

        if (error instanceof G8Error) return history.replace('/g8');
        if (error instanceof BackendError && error.statusCode === 504) return setNoConnection(true);
        await startActiveHeartBeat();
      } finally {
        if (isMounted.current) setInitialized(true);
      }
    })();

    return () => {
      clearTimeout(heartBeatTimeout);
    };
  }, []);

  if (!initialized) return <LoadingSpinner label={t('LOADING.SESSION')} />;

  if (noConnection) {
    return (
      <Container>
        <Alert color="danger" lang="en">
          {/* eslint-disable-next-line react/jsx-no-literals */}
          <p>No Internet connection</p>
        </Alert>
      </Container>
    );
  }

  return <AuthContext.Provider value={contextState}>{children}</AuthContext.Provider>;
};
export const AuthProvider = React.memo(AuthProviderComponent);

export const useAuth = () => React.useContext(AuthContext);

export default AuthContext;
