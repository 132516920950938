import classNames from 'classnames';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'reactstrap/lib/Button';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import CardText from 'reactstrap/lib/CardText';
import CardTitle from 'reactstrap/lib/CardTitle';

import { getLocationFromProduct } from '@ttstr/reducers/locations';
import { isAbsoluteURL } from '@ttstr/utils';

import { useCurrency } from '../Intl/Currency';
import DateComponent from '../Intl/DateComponent';
import { useProductDetailLink, useProductDetails } from '../ProductDetail';
import { useShopConfig } from '../ShopConfig/ShopConfigContext';
import { SushiProps } from './Sushi';

type Props = Readonly<SushiProps>;

const Nigiri: React.FC<Props> = ({
  product,
  showSupertitle,
  showTitle,
  showSubtitle,
  showLocation,
  showCity,
  showCity2,
  showBrand = false,
  showCategories,
  showTags,
  className,
  // imageClassName,
}) => {
  const { t } = useTranslation();
  const { locales } = useShopConfig();
  const currency = useCurrency();
  const { categoriesReceived, categories, tagsReceived, tags, locations } = useProductDetails();
  const productDetailLink = useProductDetailLink();
  const currentTags = tagsReceived ? product.tag_ids.map((id) => tags[id] ?? null).filter((t) => t !== null) : null;

  const isAbsolute = product.redirect && isAbsoluteURL(product.redirect);

  return (
    <Card
      className={classNames(
        'nigiri mb-3',
        className,
        product.sold_out_status,
        product.tag_ids.map((i) => `tag_${i}`)
      )}
    >
      <meta itemProp="sku" content={String(product.id)} />
      <link itemProp="image" href={product.image.url} />
      <CardBody className="nigiri-content">
        {/* <img src={product.image.thumb.url} className={imageClassName} alt={product.title} loading="lazy" /> */}
        {product.sold_out_status && (
          <span className={`badge badge-danger sold-out-status sold-out-status_${product.sold_out_status}`}>
            {locales?.length === 1
              ? product.sold_out_status_text ?? t(`PRODUCT.SOLD_OUT_STATUS.${product.sold_out_status}`)
              : t(`PRODUCT.SOLD_OUT_STATUS.${product.sold_out_status}`)}
          </span>
        )}
        <div className="nigiri-date">
          {product.valid_start_on && <DateComponent value={product.valid_start_on} />}
          {product.valid_end_on && product.valid_end_on.toString() !== product.valid_start_on.toString() && (
            <React.Fragment>
              {/* eslint-disable-next-line react/jsx-no-literals */}
              <span>{'—'}</span>
              <DateComponent value={product.valid_end_on} />
            </React.Fragment>
          )}
        </div>
        <div className="nigiri-body" itemProp="name">
          {showSupertitle && product.supertitle && <CardText className="supertitle">{product.supertitle}</CardText>}
          {showTitle && (
            <CardTitle tag="h2" className="title">
              {product.title}
            </CardTitle>
          )}
          {showSubtitle && product.subtitle && <CardText className="subtitle">{product.subtitle}</CardText>}
          {showLocation && (
            <CardText className="location">
              {showCity && (
                <span className="city">
                  {getLocationFromProduct(locations, product).city}
                  {', '}
                </span>
              )}
              {showCity2 && <span className="city">{getLocationFromProduct(locations, product).city}</span>}
              <span className="location-name">{getLocationFromProduct(locations, product).title}</span>
            </CardText>
          )}
          {showCategories &&
            categoriesReceived &&
            product.category_ids.map((id) => (
              <span key={id} className="badge badge-light category">
                {categories[id]?.title}
              </span>
            ))}
          {showTags && currentTags && (
            <div className="nigiri-tags tags">
              {currentTags.map((tag) =>
                tag.icon ? (
                  <span key={tag.id} className={`badge badge-light tag tag-${tag.id} icon-tag`}>
                    <i className={`fal fa-${tag.icon} icon-tag--icon`} />
                    <span className="sr-only">{tag.title}</span>
                  </span>
                ) : (
                  <span
                    key={tag.id}
                    className={`badge badge-light tag tag-${tag.id}`}
                    style={{
                      color: tag.text_color,
                      backgroundColor: tag.bg_color,
                    }}
                  >
                    {tag.title}
                  </span>
                )
              )}
            </div>
          )}
        </div>
        <div className="nigiri-cta">
          <Button
            tag={isAbsolute ? 'a' : Link}
            {...(isAbsolute
              ? { href: product.redirect, rel: 'noopener noreferrer', target: '_self' }
              : { to: productDetailLink(product) })}
            color="primary"
            block
          >
            <span>{t(`TICKET.STARTING_FROM`, { price: currency.format(product.min_price) })}</span>
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default React.memo(Nigiri);
