import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { AppState } from '@ttstr/reducers';
import { receiveTnc } from '@ttstr/actions';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

import Container from 'reactstrap/lib/Container';

import { useIntl } from '@ttstr/components';
import { TNC } from '@ttstr/api';

interface RouteParams {
  readonly id: string;
}

const AGBView: React.FC = () => {
  const params = useParams<RouteParams>();
  const { language } = useIntl();
  const [tnc, setTnc] = React.useState<TNC>();

  const { extra_tncs } = useShallowEqualSelector(mapStateToProps);
  const { receiveTnc } = useActions(mapDispatchToProps);

  React.useEffect(() => {
    receiveTnc(params.id);
  }, [params, language]);

  React.useEffect(() => {
    setTnc(extra_tncs[Number(params.id)]);
  }, [extra_tncs]);

  return (
    <article className="my-5 agb-detail">
      <Helmet>
        <title>{tnc?.title}</title>
        <body className="page agb-detail-page" />
      </Helmet>

      <Container>
        <h1 className="mb-4">{tnc?.title}</h1>
        <section dangerouslySetInnerHTML={{ __html: tnc?.body }} />
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const extra_tncs = state.Catalog.extra_tncs;
  return {
    extra_tncs,
  };
};

const mapDispatchToProps = {
  receiveTnc,
};

export default AGBView;
