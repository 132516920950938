export { default as BrandsView } from './BrandsView';
export { default as BrandView } from './BrandView';
export { default as CategoriesView } from './CategoriesView';
export { default as CategoryView } from './CategoryView';
export { default as CartView } from './CartView';
export { default as AGBView } from './AGBView';
export { default as ImprintView } from './ImprintView';
export { default as TermsAndConditionsView } from './TermsAndConditionsView';
export { default as PrivacyView } from './PrivacyView';
export { default as NotFoundView } from './NotFoundView';
export { default as FaqView } from './FAQView';
export { default as PlainContentrPage } from './PlainContentrPage';
