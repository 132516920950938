import classNames from 'classnames';
import React from 'react';

interface OwnProps {
  fluid?: boolean;
  className?: string;
}

type Props = Readonly<React.PropsWithChildren<OwnProps>>;

const Container: React.FC<Props> = ({ fluid = false, className, children }) => {
  return <section className={classNames(fluid ? 'container-fluid' : 'container', className)}>{children}</section>;
};

export default React.memo(Container);
