import React from 'react';

import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import { useTranslation } from 'react-i18next';
import { useShallowEqualSelector } from '@ttstr/utils';
import { AppState } from '@ttstr/reducers';

interface OwnProps {
  closeIcon?: React.ReactNode;
}

type Props = Readonly<OwnProps>;

const CartExpiredModal: React.FC<Props> = ({ closeIcon }) => {
  const { t } = useTranslation();

  const { cartId, cartConverted } = useShallowEqualSelector(mapStateToProps);

  const previousCartId = React.useRef(cartId);
  const [cartExpired, setCartExpired] = React.useState(false);

  React.useEffect(() => {
    if (cartConverted) previousCartId.current = null;
    if (cartConverted || cartId === previousCartId.current) return setCartExpired(false);

    setCartExpired(previousCartId.current && cartId === null);
    previousCartId.current = cartId;
  }, [cartId, previousCartId, cartConverted, setCartExpired]);

  function toggle() {
    setCartExpired((isExpired) => !isExpired);
  }

  const close = closeIcon ? (
    <button className="close" onClick={toggle}>
      {closeIcon}
    </button>
  ) : null;

  return (
    <Modal isOpen={cartExpired} toggle={toggle} centered>
      <ModalHeader toggle={toggle} close={close}>
        {t('CART.EXPIRED.TITLE')}
      </ModalHeader>
      <ModalBody>{t('CART.EXPIRED.DESCRIPTION')}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle} autoFocus>
          {t('CART.EXPIRED.CONTINUE')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

function mapStateToProps(state: AppState) {
  const { cart } = state.Cart;

  return {
    cartId: cart?.id,
    cartConverted: Boolean(cart?.cart_token),
  };
}

export default React.memo(CartExpiredModal);
