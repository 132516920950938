import React from 'react';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { SoldOutStatus } from '@ttstr/api';

export type VariantsNotAvailableReason =
  | 'CANCELLED'
  | 'EXPIRED'
  | 'GENERAL'
  | 'RESCHEDULED'
  | 'SOLD_OUT'
  | 'STARTS_SOON';

export function mapSoldOutStatusToReason(status: SoldOutStatus): VariantsNotAvailableReason {
  switch (status) {
    case 'cancelled':
      return 'CANCELLED';
    case 'rescheduled_active':
      return 'RESCHEDULED';
    case 'rescheduled':
      return 'RESCHEDULED';
    case 'sold_out':
      return 'SOLD_OUT';
    case 'starts_soon':
      return 'STARTS_SOON';

    default:
      return 'GENERAL';
  }
}

interface OwnProps {
  reason?: VariantsNotAvailableReason;
  reasonPlainText?: string;
}

type Props = Readonly<OwnProps>;
/*
  Schema.org ItemAvailability Statuses:

  Discontinued
  InStock
  InStoreOnly
  LimitedAvailability
  OnlineOnly
  OutOfStock
  PreOrder
  PreSale
  SoldOut
 */
const VariantsNotAvailable: React.FC<Props> = ({ reason = 'GENERAL', reasonPlainText }) => {
  const { t } = useTranslation();
  return (
    <>
      {reason === 'CANCELLED' && <link itemProp="availability" href="http://schema.org/Discontinued" />}
      {reason === 'EXPIRED' && <link itemProp="availability" href="http://schema.org/SoldOut" />}
      {reason === 'GENERAL' && <link itemProp="availability" href="http://schema.org/OutOfStock" />}
      {reason === 'RESCHEDULED' && <link itemProp="availability" href="http://schema.org/Discontinued" />}
      {reason === 'SOLD_OUT' && <link itemProp="availability" href="http://schema.org/SoldOut" />}
      <Alert color="info" className="mt-5 mb-4">
        {reasonPlainText || t(`VARIANTS.NOT_AVAILABLE.${reason}`)}
      </Alert>
    </>
  );
};

export default React.memo(VariantsNotAvailable);
