import React from 'react';
import { useTranslation } from 'react-i18next';

import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
// @ts-ignore
import UncontrolledDropdown from 'reactstrap/lib/UncontrolledDropdown';

import { useShopConfig } from '../ShopConfig/ShopConfigContext';
import { useIntl } from './IntlContext';

const labelMap: { [key: string]: string } = {
  de: 'Deutsch',
  en: 'English',
  fr: 'Français',
};

function getLabel(locale: keyof typeof labelMap) {
  return locale in labelMap ? labelMap[locale] : String(locale);
}
const LanguageSwitcher: React.FC = () => {
  const { t } = useTranslation();
  const { locales } = useShopConfig();
  const { language } = useIntl();

  if (!locales || locales.length < 2) return null;

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret title={t(`NAVIGATION.LANGUAGE`)}>
        <i className="fal fa-language" />
        <span className="sr-only">{t(`NAVIGATION.LANGUAGE`)}</span> <sub className="text-muted">{language}</sub>
      </DropdownToggle>
      <DropdownMenu right>
        {locales.map((locale) => (
          <Language key={locale} lang={locale} code={locale} label={getLabel(locale)} />
        ))}
        {/* <Language code="dev" label="[dev]" /> */}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

interface LanguageProps {
  code: string;
  label: string;
  lang?: string;
}

const LanguageComponent: React.FC<LanguageProps> = ({ code, lang, label }) => {
  const { language, setLanguage } = useIntl();
  const active = language.split('-')[0] === code;
  return (
    <DropdownItem active={active} lang={lang} onClick={() => setLanguage(code)}>
      {label} {active && <Check />}
    </DropdownItem>
  );
};
const Language = React.memo(LanguageComponent);

const Check = React.memo(() => {
  return <i className="fal fa-check mx-2" />;
});

export default React.memo(LanguageSwitcher);
