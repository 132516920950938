import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import Container from 'reactstrap/lib/Container';
import {
  Form,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  Label,
} from 'reactstrap';

import { unsubscribe, delSubscription } from '@ttstr/api/newsletter';
import { EMAIL_PATTERN } from '@ttstr/utils';
import { LoadingSpinner } from './';

const FAILED = 'FAILED';
const NOT_FOUND = 'NOT_FOUND';

type UnsubscribeWithToken = {
  confirmationToken: string;
};
type UnsubscribeWithEmail = {
  email: string;
};

const Newsletter: React.FC = () => {
  const { t } = useTranslation();
  const { confirmationToken } = useParams();
  const [success, setSuccess] = React.useState<boolean>(false);
  const [unsubscribeError, setUnsubscribeError] = React.useState<string>(null);
  const {
    register: registerWithEmail,
    handleSubmit: handleSubmitWithEmail,
    formState: formStateWithEmail,
    errors: errorsWithEmail,
  } = useForm<UnsubscribeWithEmail>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { isSubmitting: isSubmittingWithEmail } = formStateWithEmail;
  const emailErrors = errorsWithEmail.email;
  const emailHasErrors = Boolean(emailErrors);

  const {
    handleSubmit: handleSubmitWithToken,
    formState: formStateWithToken,
    register: registerWithToken,
  } = useForm<UnsubscribeWithToken>();
  const { isSubmitting: isSubmittingWithToken } = formStateWithToken;

  async function handleUnsubscribe({ email }: UnsubscribeWithEmail) {
    try {
      setUnsubscribeError(null);
      await unsubscribe(email);
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setUnsubscribeError(FAILED);
    }
  }

  async function handleDelSubscription({ confirmationToken }: UnsubscribeWithToken) {
    try {
      setUnsubscribeError(null);
      await delSubscription(confirmationToken);
      setSuccess(true);
    } catch (error) {
      // debugger;
      setSuccess(false);
      switch (error.statusCode) {
        case 404:
          setUnsubscribeError(NOT_FOUND);
          break;
        default:
          setUnsubscribeError(FAILED);
          break;
      }
    }
  }

  if (isSubmittingWithEmail || isSubmittingWithToken) return <LoadingSpinner label={t(`LOADING.DATA`)} />;
  if (success) return <i className="fa fa-check success fa-10x text-center"></i>;

  return (
    <article className="my-5">
      <Container>
        <Helmet>
          <title>{t(`NEWSLETTER.UNSUBSCRIBE.TITLE`)}</title>
        </Helmet>
        <h1 className="text-center mb-4">{t(`NEWSLETTER.UNSUBSCRIBE.TITLE`)}</h1>
        {confirmationToken ? (
          <Form onSubmit={handleSubmitWithToken(handleDelSubscription)} noValidate>
            <input type="hidden" name="confirmationToken" value={confirmationToken} ref={registerWithToken} />
            {unsubscribeError && <p className="text-danger">{t(`NEWSLETTER.${unsubscribeError}`)}</p>}
            <Button type="submit" block>
              {t(`NEWSLETTER.UNSUBSCRIBE.ACTION`)}
            </Button>
          </Form>
        ) : (
          <Form onSubmit={handleSubmitWithEmail(handleUnsubscribe)} noValidate>
            <FormGroup>
              <Label for="email" className="sr-only">
                {t(`CUSTOMER.EMAIL`)}
              </Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fal fa-envelope" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="email"
                  placeholder={t(`CUSTOMER.EMAIL`)}
                  name="email"
                  invalid={emailHasErrors}
                  innerRef={registerWithEmail({
                    required: true,
                    validate: {
                      'STRING.EMAIL': (value) => EMAIL_PATTERN.test(value),
                    },
                  })}
                />
              </InputGroup>
              {emailHasErrors && <FormFeedback>{t(`FORM.VALIDATION.${emailErrors.type.toUpperCase()}`)}</FormFeedback>}
            </FormGroup>
            {unsubscribeError && <p className="text-danger">{t(`NEWSLETTER.${unsubscribeError}`)}</p>}
            <Button type="submit" block>
              {t(`NEWSLETTER.UNSUBSCRIBE.ACTION`)}
            </Button>
          </Form>
        )}
      </Container>
    </article>
  );
};

export default React.memo(Newsletter);
