import React from 'react';

import { ContentrParagraphType, AnyContentrParagraph, RenderedFormContentrParagraphConfig } from '@ttstr/api';
import WysiwygParagraph from './ContentrParagraph/WysiwygParagraph';
import FormParagraph from './ContentrParagraph/FormParagraph';
import HeroImgParagraph from './ContentrParagraph/HeroImgParagraph';

interface OwnProps {
  paragraph: AnyContentrParagraph;
}

type Props = Readonly<OwnProps>;

const ContentrParagraph: React.FC<Props> = ({ paragraph }) => {
  const child = React.useMemo(() => {
    switch (paragraph.type) {
      case ContentrParagraphType.WysiwygParagraph:
        return <WysiwygParagraph data={paragraph.rendered_data} />;
      case ContentrParagraphType.FormParagraph:
        return <FormParagraph data={paragraph.rendered_data as RenderedFormContentrParagraphConfig} />;
      case ContentrParagraphType.HeroImgParagraph:
        return <HeroImgParagraph data={paragraph.rendered_data} />;
      default:
        // Comment out if you want to get notified about unknown paragraphs (DEV only)
        // return null;

        // Safety net to not show unknown contentr paragraphs in production
        if (process.env.NODE_ENV === 'production') return null;

        return (
          <div className="alert alert-warning">
            <h5>
              {'🧐 Unknown Contentr Edit Paragraph '}
              <code>{paragraph.type}</code>
            </h5>
            <pre className="pre-scrollable mb-0">
              <code>{JSON.stringify(paragraph.rendered_data, null, 2)}</code>
            </pre>
          </div>
        );
    }
  }, [paragraph]);

  if (!child) return null;

  return (
    <section className="contentr-paragraph" data-type={paragraph.type}>
      {child}
    </section>
  );
};

export default React.memo(ContentrParagraph);
