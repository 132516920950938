import React from 'react';

interface OwnProps {
  data: {
    img?: string;
  };
}

type Props = Readonly<OwnProps>;

const HeroImgParagraph: React.FC<Props> = ({ data }) => {
  if (!data) return null;
  if (!data.img) return null;

  return <img src={data.img} alt="contentr img test" />;
};

export default React.memo(HeroImgParagraph);
