import classNames from 'classnames';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'reactstrap/lib/Button';

import { loadCart } from '@ttstr/actions/cart';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

import Container from '../Container/Container';

const ShoppingFinisher: React.FC = () => {
  const { t } = useTranslation();
  const { cartReceived, items, isLocked } = useShallowEqualSelector(mapStateToProps);
  const { loadCart } = useActions(mapDispatchToProps);

  React.useEffect(() => {
    if (!cartReceived) loadCart();
  }, []);

  if (cartReceived && isLocked) return null;

  return (
    <div className={classNames('shoppingcart-finisher py-2', { 'is-visible': cartReceived && items })}>
      <Container fluid>
        <div className="d-flex flex-column flex-md-row-reverse justify-content-md-between align-items-md-center">
          <Button tag={Link} color="primary" size="lg" to="/checkout">
            {t(`CART.GOTO_CHECKOUT`)}
          </Button>
        </div>
      </Container>
    </div>
  );
};

function mapStateToProps(state: AppState) {
  const { cart, loading, expiresIn, cartReceived } = state.Cart;
  const isLocked = Boolean(cart?.cart_token);
  const items = cart?.items ? Object.values(cart.items) : [];
  const itemSum = items.reduce((sum, item) => sum + item.quantity, 0);

  return {
    cartReceived,
    expiresIn,
    isLocked,
    items: itemSum,
    loading,
  };
}

const mapDispatchToProps = {
  loadCart,
};

export default React.memo(ShoppingFinisher);
