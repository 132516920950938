import classNames from 'classnames';

import React from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from 'reactstrap/lib/Button';
import Collapse from 'reactstrap/lib/Collapse';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import Label from 'reactstrap/lib/Label';
import Spinner from 'reactstrap/lib/Spinner';

import { requestAddCoupon } from '@ttstr/actions/cart';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector, useToggle } from '@ttstr/utils';

interface OwnProps {
  initialOpen?: boolean;
}

interface StateProps {
  readonly cartReceived: boolean;
  readonly loading: boolean;
  readonly items: number;
  readonly isLocked: boolean;
}

type Props = Readonly<OwnProps>;

interface CouponValues {
  token: string;
}

let instances = 0;

const Coupon: React.FC<Props> = ({ initialOpen = false}) => {
  const instance = React.useMemo(() => ++instances, []);
  const { t } = useTranslation();
  const [isOpen, toggleIsOpen, setIsOpen] = useToggle(initialOpen);
  const [hasError, setHasError] = React.useState(false);
  const { cartReceived, loading, items, isLocked } = useShallowEqualSelector(mapStateToProps);
  const { requestAddCoupon } = useActions(mapDispatchToProps);
  const { register, reset, handleSubmit, formState } = useForm<CouponValues>();
  const { isSubmitting } = formState;

  if (isLocked) return null;
  if (!cartReceived && loading) return null;
  if (!items) return null;

  async function onSubmit({ token }: CouponValues) {
    try {
      setHasError(false);
      if (!token) return setHasError(true);
      await requestAddCoupon(token);
      reset();
      setIsOpen(initialOpen);
    } catch (e) {
      setHasError(true);
    }
  }

  return (
    <div className="coupon-container">
      <Collapse isOpen={!isOpen}>
        <Button type="button" color="link" className="mb-4" onClick={toggleIsOpen}>
          {t(`VOUCHER.GET_STARTED`)}
        </Button>
      </Collapse>
      <Collapse isOpen={isOpen}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup tag="fieldset" disabled={(!cartReceived && loading) || isSubmitting}>
            <Label htmlFor={`token-${instance}`}>{t('VOUCHER.REDEEM_TITLE')}</Label>
            <InputGroup className={classNames({ 'is-invalid': hasError })}>
              <Input
                type="text"
                id={`token-${instance}`}
                name="token"
                invalid={hasError}
                // eslint-disable-next-line react/jsx-no-literals
                placeholder="111111-22222-333333"
                autoFocus
                autoComplete="off"
                innerRef={register}
              />
              <InputGroupAddon addonType="append">
                <Button type="submit" color="secondary">
                  {t('VOUCHER.REDEEM')}
                  <span className={classNames('spinner-wrapper', { invisible: !isSubmitting })}>
                    <Spinner color="primary" size="sm" />
                  </span>
                </Button>
              </InputGroupAddon>
            </InputGroup>
            {hasError && <p className="invalid-feedback">{t('VOUCHER.ERROR')}</p>}
          </FormGroup>
        </Form>
      </Collapse>
    </div>
  );
};

function mapStateToProps(state: AppState): StateProps {
  const { loading, cartReceived, cart } = state.Cart;
  const items = cartReceived ? cart.items.length : 0;
  const isLocked = cartReceived && Boolean(cart.cart_token);
  return {
    cartReceived,
    loading,
    items,
    isLocked,
  };
}

const mapDispatchToProps = {
  requestAddCoupon,
};

export default React.memo(Coupon);
