import classNames from 'classnames';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Button from 'reactstrap/lib/Button';
import Card from 'reactstrap/lib/Card';
import CardTitle from 'reactstrap/lib/CardTitle';
import Collapse from 'reactstrap/lib/Collapse';

import { FAQ, IndexedFAQs, getFaq } from '@ttstr/api/catalog';
import { useIntl } from '@ttstr/components';
import Container from '../Container/Container';
import LoadingSpinner from '../Loading/LoadingSpinner';

const FAQView: React.FC = () => {
  const { t } = useTranslation();
  const { language } = useIntl();
  const [faqs, setFaqs] = React.useState<IndexedFAQs>();
  const [loading, setLoading] = React.useState(true);
  const [openState, setOpenState] = React.useState<number[]>([]);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setFaqs(await getFaq());
      } finally {
        setLoading(false);
      }
    })();
  }, [language]);

  const toggleFaq = (faqId: number) => {
    if (openState.includes(faqId)) {
      setOpenState(openState.filter((id) => id !== faqId));
    } else {
      setOpenState([...openState, faqId]);
    }
  };

  const isOpen = (id: number) => openState.includes(id);

  if (loading) return <LoadingSpinner label={t(`LOADING.DATA`)} />;

  return (
    <article className="my-5">
      <Helmet>
        <title>{t(`FAQ.TITLE`)}</title>
        <body className="page faq-page" />
      </Helmet>

      <Container>
        <h1 className="text-center mb-4">{t(`FAQ.TITLE`)}</h1>

        {Object.values(faqs)
          .sort((a, b) => a.position - b.position)
          .map((outerFaq: FAQ) =>
            Boolean(outerFaq.answer) && outerFaq.children.length > 0 ? (
              <Card key={outerFaq.id} body className="faq faq-outer">
                <CardTitle className="faq-question" aria-expanded={isOpen(outerFaq.id)}>
                  <Button
                    size="lg"
                    color="link"
                    block
                    className="text-left mx-0 d-flex align-items-baseline justify-content-between text-decoration-none"
                    onClick={() => toggleFaq(outerFaq.id)}
                  >
                    {outerFaq.question}
                    <i className={classNames('fa ml-2', isOpen(outerFaq.id) ? 'fa-chevron-up' : 'fa-chevron-down')}>
                      {' '}
                    </i>
                  </Button>
                </CardTitle>
                <div dangerouslySetInnerHTML={{ __html: outerFaq.answer }} className="faq-answer" />

                <Collapse isOpen={isOpen(outerFaq.id)}>
                  {outerFaq.children
                    .sort((a, b) => a.position - b.position)
                    .map((innerFaq: FAQ) => (
                      <Card key={innerFaq.id} body className="faq faq-inner">
                        <CardTitle className="faq-question" aria-expanded={isOpen(outerFaq.id)}>
                          <Button
                            size="m"
                            color="link"
                            block
                            className="text-left mx-0 d-flex align-items-baseline justify-content-between text-decoration-none"
                            onClick={() => toggleFaq(innerFaq.id)}
                          >
                            {innerFaq.question}
                            <i
                              className={classNames(
                                'fa ml-2',
                                isOpen(outerFaq.id) ? 'fa-chevron-up' : 'fa-chevron-down'
                              )}
                            />
                          </Button>
                        </CardTitle>
                        <Collapse isOpen={isOpen(innerFaq.id)}>
                          <div dangerouslySetInnerHTML={{ __html: innerFaq.answer }} className="faq-answer" />
                        </Collapse>
                      </Card>
                    ))}
                </Collapse>
              </Card>
            ) : (
              <Card key={outerFaq.id} body className="faq faq-outer">
                <CardTitle className="faq-question" aria-expanded={isOpen(outerFaq.id)}>
                  <Button
                    size="m"
                    color="link"
                    block
                    className="text-left mx-0 d-flex align-items-baseline justify-content-between text-decoration-none"
                    onClick={() => toggleFaq(outerFaq.id)}
                  >
                    {outerFaq.question}
                    <i className={classNames('fa ml-2', isOpen(outerFaq.id) ? 'fa-chevron-up' : 'fa-chevron-down')} />
                  </Button>
                </CardTitle>
                <Collapse isOpen={isOpen(outerFaq.id)}>
                  <div dangerouslySetInnerHTML={{ __html: outerFaq.answer }} className="faq-answer" />
                </Collapse>
              </Card>
            )
          )}
      </Container>
    </article>
  );
};

export default React.memo(FAQView);
