import classNames from 'classnames';

import React from 'react';

import Label from 'reactstrap/lib/Label';
import FormGroup from 'reactstrap/lib/FormGroup';

import { Product, Variant } from '@ttstr/api';
import { useTranslation } from 'react-i18next';
import { useCurrency } from '../Intl/Currency';

interface OwnProps {
  product: Product;
  selectedVariant?: Variant;
  className?: string;
  tag?: React.ComponentType | string;
  plusSignBeforePrice?: boolean;
}

type Props = Readonly<OwnProps>;

const PriceRange: React.FC<Props> = ({ tag = FormGroup, product, selectedVariant, className, plusSignBeforePrice }) => {
  const { t } = useTranslation();
  const currency = useCurrency();
  const variants: Variant[] = React.useMemo(() => {
    if (Array.isArray(product.online_variants_attributes)) return product.online_variants_attributes;
    return Object.values(product.online_variants_attributes);
  }, [product]);
  const price = selectedVariant?.total_price ?? selectedVariant?.price ?? null;
  const prices = React.useMemo(() => variants.map((v) => v.total_price ?? v.price), [variants]);
  const lowestPrice = React.useMemo(() => Math.min(...prices), [prices]);
  const highestPrice = React.useMemo(() => Math.max(...prices), [prices]);
  const hasPriceRange = product.max_price && product.min_price !== product.max_price;

  const Element = tag;
  const plusSign = plusSignBeforePrice ? '+\u00A0' : '';

  return (
    <Element
      className={classNames('price', className)}
      itemProp="offers"
      itemType={hasPriceRange ? 'http://schema.org/AggregateOffer' : 'http://schema.org/Offer'}
      itemScope
    >
      {hasPriceRange ? (
        <>
          <meta itemProp="lowPrice" content={String(product.min_price)} />
          <meta itemProp="highPrice" content={String(product.max_price)} />
        </>
      ) : (
        <meta itemProp="price" content={String(product.min_price)} />
      )}
      <meta itemProp="priceCurrency" content="EUR" />
      <Label htmlFor={`price-${product.id}`} className="sr-only">
        {t(`PRODUCT.PRICE`)}
      </Label>
      {price == null ? (
        lowestPrice === highestPrice ? (
          <span>
            {plusSign}
            {currency.format(lowestPrice)}
          </span>
        ) : (
          <span>
            {/* eslint-disable-next-line react/jsx-no-literals */}
            {currency.format(lowestPrice)} - {currency.format(highestPrice)}
          </span>
        )
      ) : (
        <span>
          {plusSign}
          {currency.format(price)}
        </span>
      )}
    </Element>
  );
};

export default React.memo(PriceRange);
