import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

import Button from 'reactstrap/lib/Button';
import Collapse from 'reactstrap/lib/Collapse';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import Label from 'reactstrap/lib/Label';
import Spinner from 'reactstrap/lib/Spinner';

import { requestApplyHandleToCart } from '@ttstr/actions/cart';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector, useToggle } from '@ttstr/utils';

interface Props {
  readonly initialOpen?: boolean;
  readonly variantId: number;
}

interface HandleValues {
  token: string;
}

let instances = 0;

const ApplyHandleInput: React.FC<Props> = ({ initialOpen = false, variantId }) => {
  const instance = React.useMemo(() => ++instances, []);
  const { t } = useTranslation();
  const [isOpen, toggleIsOpen, setIsOpen] = useToggle(initialOpen);
  const [hasError, setHasError] = React.useState(false);
  const { register, reset, handleSubmit, formState: { isSubmitting } } = useForm<HandleValues>();
  const { cartReceived, loading } = useShallowEqualSelector(mapStateToProps);
  const { requestApplyHandleToCart } = useActions(mapDispatchToProps);

  async function onSubmit({ token }: HandleValues) {
    try {
      if (!token) return setHasError(true);
      
      setHasError(false);
      await requestApplyHandleToCart(token, variantId);
      reset();
      setIsOpen(initialOpen);
    } catch (e) {
      setHasError(true);
    }
  }

  if (!cartReceived && loading) return null;

  return (
    <div className="handle-container">
      <Collapse isOpen={!isOpen}>
        <Button type="button" color="link" className="mb-4" onClick={toggleIsOpen}>
          {t(`HANDLE.GET_STARTED`)}
        </Button>
      </Collapse>
      <Collapse isOpen={isOpen}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup tag="fieldset" disabled={(!cartReceived && loading) || isSubmitting}>
            <Label htmlFor={`handle-${instance}`}>{t(`HANDLE.LABEL`)}</Label>
            <InputGroup className={classNames({ 'is-invalid': hasError })}>
              <Input
                type="text"
                id={`handle-${instance}`}
                name="token"
                invalid={hasError}
                placeholder="ABCDE-123-FGHIJ-45678"
                autoFocus
                autoComplete="off"
                innerRef={register}
              />
              <InputGroupAddon addonType="append">
                <Button type="submit" color="secondary">
                  {t(`HANDLE.ACTION`)}
                  <span className={classNames('spinner-wripper', { invisible: !isSubmitting })}>
                    <Spinner color="primary" size="sm" />
                  </span>
                </Button>
              </InputGroupAddon>
            </InputGroup>
            {hasError && <p className="invalid-feedback">
              {t(`HANDLE.ERROR`)}
            </p>}
          </FormGroup>
        </Form>
      </Collapse>
    </div>
  );
};

function mapStateToProps(state: AppState) {
  const { loading, cartReceived } = state.Cart;
  return {
    cartReceived,
    loading,
  }
}

const mapDispatchToProps = {
  requestApplyHandleToCart,
}

export default React.memo(ApplyHandleInput);
