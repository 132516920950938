import React from 'react';

import { useFormContext } from 'react-hook-form';

import Button from 'reactstrap/lib/Button';
import classNames from 'classnames';

interface OwnProps {
  name: string;
  id?: string;
  className?: string;
  color?: string;
  size?: string;
  min?: number;
  max?: number;
  disabled?: boolean;
  readOnly?: boolean;
  outline?: boolean;
  innerRef?: React.Ref<HTMLInputElement>;
}

type Props = Readonly<OwnProps>;

const NumberInput: React.FC<Props> = ({
  id,
  name,
  className,
  color = 'secondary',
  size,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  disabled,
  readOnly,
  outline = true,
  innerRef,
}) => {
  const timeoutId = React.useRef<number>();
  const intervalId = React.useRef<number>();

  const { register, getValues, setValue, watch } = useFormContext();

  const value = Number(watch(name));
  const currentValueIsNaN = Number.isNaN(value);
  const stringLength = String(value).length || String(max).length;

  const decrease = React.useCallback(() => {
    const newValue = Math.max(parseInt(getValues()[name], 10) - 1, min);
    if (!Number.isNaN(newValue)) {
      setValue(name, String(newValue), { shouldValidate: true });
    }
  }, [name, min, getValues]);

  const increase = React.useCallback(() => {
    const newValue = Math.min(parseInt(getValues()[name], 10) + 1, max);
    if (!Number.isNaN(newValue)) {
      setValue(name, String(newValue), { shouldValidate: true });
    }
  }, [name, max, getValues]);

  const loop = React.useCallback((callback: CallableFunction, interval: number) => {
    window.clearInterval(intervalId.current);
    intervalId.current = window.setInterval(callback, interval);
  }, []);

  const handlePointerDown = React.useCallback(
    (callback: CallableFunction) => {
      return (e: React.PointerEvent<HTMLButtonElement>) => {
        if (e.defaultPrevented) return;
        if (disabled) return;
        if (readOnly) return;
        e.preventDefault();
        window.clearTimeout(timeoutId.current);

        timeoutId.current = window.setTimeout(() => loop(callback, 50), 300);
      };
    },
    [loop]
  );

  const handlePointerUp = React.useCallback(() => {
    window.clearTimeout(timeoutId.current);
    window.clearInterval(intervalId.current);
  }, []);

  const handleKeyDown = React.useCallback((callback: CallableFunction) => {
    return (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Enter': // fall-through
        case ' ': // Space
          callback();
          e.preventDefault();
          break;
        default:
          return;
      }
    };
  }, []);

  React.useEffect(() => {
    document.addEventListener('pointerup', handlePointerUp, false);
    document.addEventListener('scroll', handlePointerUp, false);

    return () => {
      document.removeEventListener('pointerup', handlePointerUp);
      document.removeEventListener('scroll', handlePointerUp);
    };
  }, []);

  return (
    <fieldset id={id} className={classNames('number-input', className)}>
      <div className="d-flex flex-row align-items-center">
        <Button
          type="button"
          className="text-monospace decrease"
          outline={outline}
          color={color}
          size={size}
          onPointerDown={handlePointerDown(decrease)}
          onClick={decrease}
          onKeyDown={handleKeyDown(decrease)}
          disabled={readOnly || currentValueIsNaN || value <= min}
        >
          {/* eslint-disable-line react/jsx-no-literals */}-
        </Button>
        <input
          className="form-control form-control-seamless text-center"
          type="text"
          size={stringLength}
          readOnly
          name={name}
          ref={innerRef || register}
          tabIndex={-1}
        />
        <Button
          type="button"
          className="text-monospace increase"
          outline={outline}
          color={color}
          size={size}
          onPointerDown={handlePointerDown(increase)}
          onClick={increase}
          onKeyDown={handleKeyDown(increase)}
          disabled={readOnly || currentValueIsNaN || value >= max}
        >
          {/* eslint-disable-line react/jsx-no-literals */}+
        </Button>
      </div>
    </fieldset>
  );
};

export default React.memo(NumberInput);
