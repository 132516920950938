import React from 'react';
import { useTranslation } from 'react-i18next';
import { useProductDetails } from './ProductDetailsContext';

interface OwnProps {
  merchantId: number;
}

type Props = Readonly<OwnProps>;

const MerchantInfo: React.FC<Props> = ({ merchantId }) => {
  const { t } = useTranslation();
  const { merchantsReceived, merchants } = useProductDetails();

  if (!merchantsReceived) return null;

  const merchant = merchants[merchantId];

  if (!merchant) return null;

  return (
    <aside className="">
      <h2 className="product-title">{t('PRODUCT.MERCHANT_INFO.TITLE')}</h2>
      <address>
        <strong>
          {merchant.website ? (
            <a href={merchant.website} target="_blank" rel="noopener noreferrer">
              {merchant.title}
            </a>
          ) : (
            merchant.title
          )}
        </strong>
        <br />
        {merchant.street && (
          <>
            {merchant.street}
            <br />
            {merchant.zip_code} {merchant.city}
            <br />
          </>
        )}
      </address>
      {/* <pre>{JSON.stringify(merchant, null, 2)}</pre> */}
    </aside>
  );
};

export default React.memo(MerchantInfo);
