import classNames from 'classnames';

import React from 'react';

import { useFormContext, RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getCountryMap } from '@ttstr/api/settings';
import { useMounted } from '@ttstr/utils';
import { useIntl } from '../Intl/IntlContext';
import FormField, { FormFieldProps } from './FormField';

export interface OptionType {
  label: string;
  value: string;
}

interface OwnProps extends Omit<FormFieldProps, 'types' | 'onChange'> {
  onChange?(newValue: string): void;
  validationOptions?: RegisterOptions;
}

type Props = Readonly<OwnProps>;

const CountrySelect: React.FC<Props> = ({
  name,
  disabled,
  label,
  validationOptions,
  id,
  className,
  placeholder,
  onChange,
}) => {
  const isMounted = useMounted();
  const { t } = useTranslation();
  const { language } = useIntl();
  const { trigger, watch } = useFormContext();
  const [countries, setCountries] = React.useState<OptionType[]>([]);
  const [loading, setLoading] = React.useState(false);

  const value = watch(name);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const map = await getCountryMap(language);
        if (isMounted.current) {
          setCountries(Object.entries(map).map(([value, label]) => ({ label, value })));
          await trigger(name);
        }
      } catch (e) {
        console.error(e);
      } finally {
        if (isMounted.current) setLoading(false);
      }
    })();
  }, [setLoading, getCountryMap, language, setCountries, trigger, name]);

  if (loading) {
    return (
      <FormField
        type="input"
        id={`${id || name}-loading`}
        name={name}
        label={label ?? t(`CUSTOMER.COUNTRY`)}
        className={classNames('country-select', className)}
        disabled={disabled}
        validationOptions={validationOptions}
        placeholder={t(`CUSTOMER.COUNTRY_CODES.LOADING`)}
        readOnly
      />
    );
  }

  return (
    <FormField
      type="select"
      label={label ?? t(`CUSTOMER.COUNTRY`)}
      id={id || name}
      name={name}
      className={classNames('country-select', className)}
      onChange={(e) => onChange?.(e.target.value)}
      disabled={disabled}
      placeholder={placeholder || t(`CUSTOMER.COUNTRY_CODES.PLEASE_CHOOSE`)}
      validationOptions={validationOptions}
    >
      {!countries.length && (
        <option className="country-select-no-match" value={value} disabled>
          {t('CUSTOMER.COUNTRY_CODES.NO_MATCH')}
        </option>
      )}
      {countries.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </FormField>
  );
};

export default React.memo(CountrySelect);
