import React from 'react';

import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import CustomInput from 'reactstrap/lib/CustomInput';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';

interface OwnProps {
  value: Date;
  name?: string;
  id?: string;
  onChange?(date: Date): void;
  required?: boolean;
}

type Props = Readonly<OwnProps>;

function isValidDate(date: unknown) {
  return date instanceof Date && !isNaN(date.getTime());
}
const currentYear = new Date().getFullYear();

const days = [...Array(31)].map((_, i) => i + 1);
const months = [...Array(12)].map((_, i) => i + 1);
const years = [...Array(120)].map((_, i) => i + 1 + currentYear - 120).reverse();

const BirthdayPicker = React.forwardRef<HTMLDivElement, Props>(({ value, onChange, required = false }, ref) => {
  const { t } = useTranslation();

  const currentDay = React.useMemo(() => (isValidDate(value) ? String(value.getUTCDate()) : ''), [value]);
  const currentMonth = React.useMemo(() => (isValidDate(value) ? String(value.getUTCMonth() + 1) : ''), [value]);
  const currentYear = React.useMemo(() => (isValidDate(value) ? String(value.getUTCFullYear()) : ''), [value]);

  const [selectedDay, setSelectedDay] = React.useState<string>(currentDay);
  const [selectedMonth, setSelectedMonth] = React.useState<string>(currentMonth);
  const [selectedYear, setSelectedYear] = React.useState<string>(currentYear);

  const handleChangeDay = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedDay(e.target.value);
    },
    [selectedDay]
  );

  const handleChangeMonth = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedMonth(e.target.value);
    },
    [setSelectedMonth]
  );

  const handleChangeYear = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedYear(e.target.value);
    },
    [setSelectedYear]
  );

  React.useEffect(() => {
    if (currentDay) setSelectedDay(currentDay);
    if (currentMonth) setSelectedMonth(currentMonth);
    if (currentYear) setSelectedYear(currentYear);
  }, [currentDay, currentMonth, currentYear]);

  React.useEffect(() => {
    if (!selectedDay || !selectedMonth || !selectedYear) return;
    if (selectedDay === currentDay && selectedMonth === currentMonth && selectedYear === currentYear) return;

    onChange(new Date(Date.UTC(Number(selectedYear), Number(selectedMonth) - 1, Number(selectedDay))));
  }, [selectedDay, selectedMonth, selectedYear]);

  return (
    <div ref={ref}>
      <FormGroup row>
        <Col xs={12} sm={4} className="mt-3 mt-sm-0">
          <Label htmlFor="day">{t('DATE.DAY')}</Label>
          <CustomInput
            type="select"
            name="day"
            id="day"
            value={selectedDay}
            onChange={handleChangeDay}
            required={required}
          >
            <option aria-selected={selectedDay === ''}>{t('DATE.PLEASE_CHOOSE')}</option>
            {days.map((day) => (
              <option key={day} value={day} aria-selected={String(day) === selectedDay}>
                {day}
              </option>
            ))}
          </CustomInput>
        </Col>
        <Col xs={12} sm={4} className="mt-3 mt-sm-0">
          <Label htmlFor="month">{t('DATE.MONTH')}</Label>
          <CustomInput
            type="select"
            name="month"
            id="month"
            value={selectedMonth}
            onChange={handleChangeMonth}
            required={required}
          >
            <option aria-selected={selectedMonth === ''}>{t('DATE.PLEASE_CHOOSE')}</option>
            {months.map((month) => (
              <option key={month} value={month} aria-selected={String(month) === selectedMonth}>
                {t(`DATE.MONTHS.${month}`)}
              </option>
            ))}
          </CustomInput>
        </Col>
        <Col xs={12} sm={4} className="mt-3 mt-sm-0">
          <Label htmlFor="year">{t('DATE.YEAR')}</Label>
          <CustomInput
            type="select"
            name="year"
            id="year"
            value={selectedYear}
            onChange={handleChangeYear}
            required={required}
          >
            <option aria-selected={selectedYear === ''}>{t('DATE.PLEASE_CHOOSE')}</option>
            {years.map((year) => (
              <option key={year} value={year} aria-selected={String(year) === selectedYear}>
                {year}
              </option>
            ))}
          </CustomInput>
        </Col>
      </FormGroup>
    </div>
  );
});

export default BirthdayPicker;
