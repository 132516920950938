import React from 'react';
import { Helmet } from 'react-helmet';

import { Locale } from 'date-fns';
import i18next from 'i18next';

import { isNumeric, useMounted } from '@ttstr/utils';

import Loading from '../Loading/Loading';
import LoadingSpinner from '../Loading/LoadingSpinner';
import { getFormatter as currencyFormatter } from './Currency';
import { getFormatter as dateFormatter, getLongFormatter as dateLongFormatter } from './DateComponent';
import { getFormatter as numberFormatter } from './NumberComponent';

const DEFAULT_LANGUAGE = i18next.language || 'en';

export interface IntlContextProps {
  locale: Locale;
  language: string;
  setLanguage?(newLanguage: string): void;
}

const IntlContext = React.createContext<IntlContextProps>({
  locale: null,
  language: DEFAULT_LANGUAGE,
  setLanguage: () => null,
});

export const { Consumer: IntlContextConsumer } = IntlContext;

function updateI18nextLanguage(newLanguage: string) {
  return i18next.changeLanguage(newLanguage);
}

type Props = React.PropsWithChildren<Record<string, unknown>>;

const dateFnsLocales: { [key: string]: () => Promise<Locale> } = {
  de: async () => (await import('date-fns/locale/de')).default,
  en: async () => (await import('date-fns/locale/en-US')).default,
  fr: async () => (await import('date-fns/locale/fr')).default,
  nl: async () => (await import('date-fns/locale/nl')).default,
};

const IntlProviderComponent: React.FC<Props> = ({ children }) => {
  const isMounted = useMounted();
  const [locale, setLocale] = React.useState<Locale>(null);
  const [language, setLanguage] = React.useState(i18next.isInitialized ? i18next.language : DEFAULT_LANGUAGE);
  const [initialized, setInitialized] = React.useState(false);

  const handleLanguageChanged = React.useCallback(
    async (newLanguage: string) => {
      if (newLanguage in dateFnsLocales) setLocale(await dateFnsLocales[newLanguage]());
      if (newLanguage === language) return;
      if (isMounted.current) setLanguage(newLanguage);
    },
    [language, setLanguage]
  );

  const state = React.useMemo(
    () => ({
      locale,
      language,
      setLanguage: updateI18nextLanguage,
      initialized,
    }),
    [locale, language, updateI18nextLanguage, initialized]
  );

  React.useEffect(() => {
    i18next.on('languageChanged', handleLanguageChanged);

    return () => i18next.off('languageChanged', handleLanguageChanged);
  }, [handleLanguageChanged]);

  // onComponentDidMount
  React.useEffect(() => {
    (async () => {
      try {
        // Synchronize current language with dat-fns initially
        await handleLanguageChanged(language);
      } finally {
        if (isMounted.current) setInitialized(true);
      }
    })();
  }, []);

  if (!initialized) return <LoadingSpinner />;

  return (
    <IntlContext.Provider value={state}>
      <Loading>
        <Helmet>
          <html lang={state.language} />
        </Helmet>
        {/* This "hack" makes sure all childrens will be rerendered if the language changes */}
        <React.Fragment key={state.language}>{children}</React.Fragment>
      </Loading>
    </IntlContext.Provider>
  );
};
export const IntlProvider = React.memo(IntlProviderComponent);

export const i18nextFormatter = (value: Date, format: string, language: string) => {
  if (value instanceof Date) {
    switch (format) {
      case 'long':
        return dateLongFormatter(language).format(value);
      default:
        return dateFormatter(language).format(value);
    }
  }
  if (isNumeric(value)) {
    switch (format) {
      case 'currency':
        return currencyFormatter(language).format(Number(value));
      default:
        return numberFormatter(language).format(Number(value));
    }
  }
  return value;
};

export const useIntl = () => {
  return React.useContext(IntlContext);
};

export default IntlContext;
