import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Container from 'reactstrap/lib/Container';
import FormGroup from 'reactstrap/lib/FormGroup';

import { loadCart } from '@ttstr/actions';
import { AppState } from '@ttstr/reducers';

import CartListing from '@ttstr/components/ShoppingCart/CartListing';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

const CartView: React.FC = () => {
  const { t } = useTranslation();
  const { cartReceived, noCheckoutMessage, hasItems, isLocked } = useShallowEqualSelector(mapStateToProps);
  const { loadCart } = useActions(mapDispatchToProps);

  React.useEffect(() => {
    if (!cartReceived) loadCart();
  }, [cartReceived]);

  return (
    <article className="my-5">
      <Container>
        <Helmet>
          <title>{t('CART.TITLE')}</title>
        </Helmet>
        <h1 className="text-center mb-4">{t('CART.TITLE')}</h1>
        <CartListing />
        {/* TODO: put in CheckoutButton Component (here and Navigation and ProductModal) */}
        {!isLocked && hasItems && (
          <Container fluid>
            <FormGroup className="mb-0 d-flex flex-column flex-fill">
              {!noCheckoutMessage ? (
                <Link to="/checkout" className="btn btn-primary btn-lg float-right ml-auto">
                  {t(`CART.GOTO_CHECKOUT`)}
                </Link>
              ) : (
                <>
                  <Link
                    to="#"
                    className="btn btn-primary btn-lg ml-auto disabled"
                    aria-disabled
                    aria-invalid
                    aria-errormessage="no-checkout-message"
                  >
                    {t(`CART.GOTO_CHECKOUT`)}
                  </Link>
                  <p id="no-checkout-message" className="text-danger mt-2 ml-auto">
                    {noCheckoutMessage}
                  </p>
                </>
              )}
            </FormGroup>
          </Container>
        )}
      </Container>
    </article>
  );
};

function mapStateToProps(state: AppState) {
  const { cart, loading } = state.Cart;
  const isLocked = Boolean(cart?.cart_token);
  const items = cart?.items ? Object.values(cart.items) : [];
  const noCheckoutMessage = cart?.no_checkout_message;

  return {
    cartReceived: Boolean(cart),
    loading,
    isLocked,
    hasItems: Boolean(items.length),
    noCheckoutMessage,
  };
}

const mapDispatchToProps = {
  loadCart,
};

export default React.memo(CartView);
