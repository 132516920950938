export { default as AuthRoute } from './Routing/AuthRoute';
export { default as Container } from './Container/Container';
export { default as ContentrArea } from './Contentr/ContentrArea';
export { default as Loading } from './Loading/Loading';
export { default as LoadingSpinner } from './Loading/LoadingSpinner';
export { default as Maki } from './Sushi/Maki';
export { default as Maki2020 } from './Sushi/Maki2020';
export { default as MakiGIF } from './Sushi/MakiGIF';
export { default as Masonry } from './Masonry/Masonry';
export { default as Navigation } from './Navigation/Navigation';
export { default as Nigiri } from './Sushi/Nigiri';
export { default as NumberInput } from './Input/NumberInput';
export { default as NativeSlider } from './ProductDetail/NativeSlider';
export { default as ScrollToTop } from './Navigation/ScrollToTop';
export { default as SimpleCart } from './ShoppingCart/SimpleCart';
export { default as ShoppingFinisher } from './ShoppingCart/ShoppingFinisher';
export { default as PriceRange } from './VariantListing/PriceRange';
export { default as VariantChooser } from './VariantListing/VariantChooser';
export { default as VariantListing } from './VariantListing/VariantListing';
export { default as Reveal } from './Reveal/Reveal';
export { default as Video } from './Video/Video';
export * from './VariantListing/VariantChooser';
export * from './Contentr/ContentrContext';
export * from './ShopConfig/ShopConfigContext';
export * from './Auth/AuthContext';
export * from './Auth/G8Manager';
export * from './Intl/IntlContext';
export * from './Customer';
export * from './ProductDetail';
export { default as Newsletter } from './Newsletter';
export { default as RedirectToProductDetailUrlWithSlug } from './Routing/RedirectToProductDetailUrlWithSlug';
export { default as Routes } from './Routing/Routes';
export { default as SimpleCard } from './Listing/Cells/SimpleCard';
export { default as Masonry2 } from './Listing/Masonry2';
export { default as InfoSection } from './Catalog/InfoSection';
export { default as CategoryNavLinks } from './Navigation/CategoryNavLinks';
export { default as ExtraTncInfo } from './Catalog/ExtraTncInfo';
export { default as BrandPageLinks } from './Brand/BrandPageLinks';
export { default as ApplyHandleInput } from './ApplyHandle/ApplyHandleInput';
export { default as TicketMarkup } from './Seo/TicketMarkup';
export { default as ProductMarkup } from './Seo/ProductMarkup';
export { default as Dated } from './Dated/Dated';
export * from './Views';

// if (process.env.NODE_ENV === 'production') {
window.addEventListener('load', () => {
  /* eslint-disable no-console */
  console?.group?.('JOBOFFER.386.BAT');
  console?.log?.(
    '%c✨ we are hiring ✨',
    'font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113); padding: 1rem; '
  );
  console?.log?.(
    '%cAre you interested in developing leading eCommerce systems with Typescript and React together with Rails? Hit us up @ https://tickettoaster.de/kontakt',
    'padding: 1rem; color: teal;'
  );
  console?.groupEnd?.();
  /* eslint-enable no-console */
});
// }
