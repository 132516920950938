import React from 'react';

import DayPicker, { DayModifiers } from 'react-day-picker';
import { LocaleUtils } from 'react-day-picker/types';

import format from 'date-fns/format';
import parseJSON from 'date-fns/parseJSON';

import IntlContext from '../Intl/IntlContext';

interface OwnProps {
  from: Date;
  to: Date;
  min?: Date;
  max?: Date;
  disabled?: boolean;
  onDayClick?(day: Date, modifiers: DayModifiers, e: React.MouseEvent<HTMLDivElement>): void;
}

type Props = Readonly<OwnProps>;

type Months = [string, string, string, string, string, string, string, string, string, string, string, string];
type WeekDays = [string, string, string, string, string, string, string];

const DayRangePicker: React.FC<Props> = ({ from, to, min, max, disabled, onDayClick }) => {
  const { language, locale } = React.useContext(IntlContext);

  const months: Months = React.useMemo(
    () => Array.from({ length: 12 }).map((_, i) => locale?.localize.month(i, { width: 'short' })) as any,
    [locale]
  );
  const weekdaysShort: WeekDays = React.useMemo(
    () => Array.from({ length: 7 }).map((_, i) => locale?.localize.day(i, { width: 'short' })) as any,
    [locale]
  );
  const weekdaysLong: WeekDays = React.useMemo(
    () => Array.from({ length: 7 }).map((_, i) => locale?.localize.day(i, { width: 'wide' })) as any,
    [locale]
  );

  const localeUtils: LocaleUtils = React.useMemo(
    () => ({
      formatDay(day) {
        return format(day, 'd', { locale });
      },
      formatMonthTitle(month: Date) {
        return format(month, 'LLLL Y', { locale });
      },
      formatWeekdayLong(weekday: number) {
        return weekdaysLong[weekday];
      },
      formatWeekdayShort(weekday: number) {
        return weekdaysShort[weekday];
      },
      getFirstDayOfWeek() {
        return 0;
      },
      getMonths() {
        return months;
      },

      // Unused
      parseDate(dateString: string) {
        return parseJSON(dateString);
      },
      // Unused
      formatDate(date) {
        return format(date, 'L', { locale });
      },
    }),
    [locale, weekdaysLong, weekdaysShort]
  );

  return (
    <DayPicker
      locale={language}
      localeUtils={localeUtils}
      disabledDays={disabled ? () => true : { before: min, after: max }}
      className="Selectable"
      modifiers={{ start: from, end: to }}
      selectedDays={[from, { from, to }]}
      numberOfMonths={2}
      onDayClick={onDayClick}
      month={from}
      pagedNavigation
    />
  );
};

export default React.memo(DayRangePicker);
