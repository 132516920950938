/**
 * Displays a title and a description.
 * For use in BrandView, CategoryView, etc
 */

import React from 'react';
import { Helmet } from 'react-helmet';

import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import { useShopConfig } from '../ShopConfig/ShopConfigContext';

type Props = {
  title: string;
  subtitle?: string;
  imageUrl?: string;
  description: string;
};

const InfoSection: React.FC<Props> = ({ title, subtitle, imageUrl, description }) => {
  const [openState, setOpenState] = React.useState(false);
  const { sites } = useShopConfig();

  const toggelOpenState = () => setOpenState((openState) => !openState);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
        {/* TODO: canonical url */}
      </Helmet>
      <h1 className="py-5 text-center">{title}</h1>
      <h2 className="text-center">{subtitle}</h2>

      <Container>
        {/* <Row>
          {image.productthumb && (
            <Col>
              <img src={image.productthumb.url} alt="" className="image" />
            </Col>
          )}
          <Col>
            <h1 className="title">{title}</h1>
            {description && (
              <section dangerouslySetInnerHTML={{ __html: description }} itemProp="description" />
            )}
          </Col>
        </Row> */}
        {description && (
          <Row>
            {imageUrl && (
              <Col>
                <img src={imageUrl} alt="" className="image" />
              </Col>
            )}
            {sites?.brand?.collapseDescription ? (
              <Col
                className="pt-5"
                onClick={() => toggelOpenState()}
                onKeyPress={() => toggelOpenState()}
                role="button"
                tabIndex={0}
              >
                <div
                  className={openState ? '' : 'closed'}
                  id="description"
                  dangerouslySetInnerHTML={{ __html: description }}
                  itemProp="description"
                />
                <i className={openState ? '' : 'fa fa-chevron-down'}> </i>
              </Col>
            ) : (
              <Col className="pt-5">
                <div id="description" dangerouslySetInnerHTML={{ __html: description }} itemProp="description" />
              </Col>
            )}
          </Row>
        )}
      </Container>
    </>
  );
};

export default InfoSection;
