import classNames from 'classnames';

import React from 'react';

import { useTranslation } from 'react-i18next';

import { FormProvider, useForm } from 'react-hook-form';

import Button from 'reactstrap/lib/Button';
import Collapse from 'reactstrap/lib/Collapse';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import { requestCustomer, requestDeleteCustomer, requestUpdateCustomer } from '@ttstr/actions/customer';
import { ModifiableCustomer } from '@ttstr/api';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector, leftMerge } from '@ttstr/utils';

import FormField from '../Form/FormField';
import ValidationAlert from '../Form/ValidationAlert';
import BirthdayPicker from '../Input/BirthdayPicker';
import LoadingSpinner from '../Loading/LoadingSpinner';
import CustomerDataFields from './CustomerDataFields';

const defaultInitialValues: ModifiableCustomer = {
  allow_promotion: true,
  birthday_on: null,
  address_addition: '',
  city: '',
  companyname: '',
  country: 'DE',
  fax: '',
  firstname: '',
  lastname: '',
  mobile: '',
  phone: '',
  state: '',
  street: '',
  title: '',
  zip_code: '',
};

const CustomerUpdate: React.FC = () => {
  const [editing, setEditing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [deleteAccountModal, setDeleteAccountModal] = React.useState(false);

  const { t } = useTranslation();
  const { customer } = useShallowEqualSelector(mapStateToProps);
  const { requestCustomer, requestUpdateCustomer } = useActions(mapDispatchToProps);
  const initialValues = React.useMemo(() => leftMerge(defaultInitialValues, customer), [customer]);
  const formContext = useForm<ModifiableCustomer>({
    mode: 'onChange',
    defaultValues: initialValues,
  });
  const { register, handleSubmit, reset, formState, setValue, watch } = formContext;
  const { isSubmitting, isValid } = formState;

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await requestCustomer();
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const toggleDeleteAccountModal = () => setDeleteAccountModal(!deleteAccountModal);

  const deleteAccount = async () => {
    await requestDeleteCustomer();   
  };

  const onSubmit = async (values: ModifiableCustomer) => {
    await requestUpdateCustomer(values);
  };

  const handleReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setEditing(false);
    reset(initialValues);
  };

  const birthdayOn = watch('birthday_on');

  return (
    <section>
      <FormProvider {...formContext}>
        <Form onSubmit={handleSubmit(onSubmit)} onReset={handleReset} className="mb-4">
          <fieldset disabled={!editing || isSubmitting}>
            <FormGroup tag="fieldset" className="mb-0">
              <legend>
                {t(`CUSTOMER.DATA`)}{' '}
                {<i className={classNames('ml-2 text-muted', editing ? 'fal fa-lock-open' : 'fal fa-lock')} />}
              </legend>
              <CustomerDataFields mode="modify" />
              <FormGroup>
                <Label htmlFor="birthday_on">{t(`CUSTOMER.DOB`)}</Label>
                <BirthdayPicker
                  name="birthday_on"
                  id="birthday_on"
                  value={birthdayOn}
                  onChange={(value) => setValue('birthday_on', value, { shouldValidate: true })}
                  ref={() => register({ name: 'birthday_on' })}
                />
              </FormGroup>
            </FormGroup>
            <FormGroup tag="fieldset">
              <legend>{t(`CUSTOMER.SETTINGS`)}</legend>
              <FormField
                type="checkbox"
                name="allow_promotion"
                id="allow_promotion"
                label={t(`CUSTOMER.ALLOW_PROMOTION`)}
                innerRef={register}
              />
              <Button id="delete_account" color="danger" onClick={toggleDeleteAccountModal}>
                <i className="fa fa-exclamation-triangle mr-1" aria-hidden="true" /> {t(`CUSTOMER.DELETE_ACCOUNT`)}
              </Button>
              <Modal isOpen={deleteAccountModal} toggle={toggleDeleteAccountModal}>
                <ModalHeader toggle={toggleDeleteAccountModal}>{t(`CUSTOMER.DELETE_ACCOUNT`)}</ModalHeader>
                <ModalBody>
                  {t(`CUSTOMER.DELETE_ACCOUNT_ALERT`)}
                </ModalBody>
                <ModalFooter>
                  <Button color="link" onClick={toggleDeleteAccountModal}>
                    {t(`CUSTOMER.DELETE_ACCOUNT_CANCEL`)}
                  </Button>
                  <Button color="danger" onClick={deleteAccount}>
                    {t(`CUSTOMER.DELETE_ACCOUNT_CONFIRM`)}
                  </Button>
                </ModalFooter>
              </Modal>
            </FormGroup>
            <hr />
            <ValidationAlert className="mb-2" />
            <Collapse isOpen={editing}>
              <FormGroup className="d-flex flex-column flex-md-row-reverse justify-content-md-between align-items-md-center">
                <Button type="submit" color="primary" size="lg" className="mb-3" disabled={!isValid}>
                  {t('CUSTOMER.UPDATE')}
                </Button>
                <Button type="reset" color="link" className="mb-3">
                  {t('CUSTOMER.RESET')}
                </Button>
              </FormGroup>
            </Collapse>
          </fieldset>
          <Collapse isOpen={!editing}>
            <FormGroup>
              <Button type="button" block color="warning" onClick={() => setEditing(true)} disabled={loading}>
                <i className="fal fa-lock mr-2" /> {t('CUSTOMER.UPDATE_CUSTOMER_DATA')}
              </Button>
            </FormGroup>
          </Collapse>
        </Form>
      </FormProvider>
      {loading && <LoadingSpinner label={t(`LOADING.DATA`)} />}
    </section>
  );
};

function mapStateToProps(state: AppState) {
  const { customer } = state.Customer;
  return {
    customer,
  };
}
const mapDispatchToProps = {
  requestCustomer,
  requestUpdateCustomer,
  requestDeleteCustomer,
};

export default React.memo(CustomerUpdate);
