import classNames from 'classnames';

import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'reactstrap/lib/Button';
import CarouselControl from 'reactstrap/lib/CarouselControl';
// import CarouselIndicators from 'reactstrap/lib/CarouselIndicators';

import debounce from 'lodash/debounce';
import PreviewImage from '../Sushi/PreviewImage';

interface OwnProps {
  images: string[];
  thumbs?: string[];
  links?: string[];
  className?: string;
  interval?: number;
  loading?: 'eager' | 'lazy';
  thumbClassName?: string;
  thumbContainerClassName?: string;
  previewImage?: string;
}

type Props = Readonly<OwnProps>;

const NativeSlider: React.FC<Props> = ({
  images,
  thumbs,
  links,
  className,
  loading = 'lazy',
  thumbClassName,
  thumbContainerClassName,
  previewImage,
  interval = 5000,
}) => {
  const { t } = useTranslation();
  const timeoutId = React.useRef<NodeJS.Timeout>();
  const [sliderRef, setSliderRef] = React.useState<HTMLDivElement>(); // Callback Ref
  const itemRefs = React.useRef(images.map(() => React.createRef<HTMLDivElement>()));
  const index = React.useRef(0); // Required for setTimeout
  const [activeIndex, setActiveIndex] = React.useState(index.current);

  // const itemSizePercent = .75;
  const itemSizePercent = 1;

  const scrollTo = React.useCallback(
    (x: number, y: number) => {
      if (!sliderRef) return;
      if ('scrollTo' in sliderRef) return sliderRef.scrollTo(x, y);
      // Probably a Microsoft product
      (sliderRef as any).scrollLeft = x;
      (sliderRef as any).scrollTop = y;
    },
    [sliderRef]
  );

  const goToIndex = React.useCallback(
    (idx: number) => {
      const index = (idx < 0 ? images.length - 1 : idx) % images.length;
      const item = itemRefs.current[index];
      if (!item?.current) return;
      // @ts-ignore
      const left = item.current.offsetLeft - item.current.clientWidth * ((1 - itemSizePercent) / 2);

      scrollTo(left || 0, 0);
      startLoop();
    },
    [sliderRef]
  );

  const previous = React.useCallback(() => {
    goToIndex(index.current - 1);
  }, [sliderRef]);

  const next = React.useCallback(() => {
    goToIndex(index.current + 1);
  }, [sliderRef]);

  const startLoop = React.useCallback(() => {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      next();
    }, interval);
  }, [sliderRef]);

  const stopLoop = React.useCallback(() => {
    clearTimeout(timeoutId.current);
  }, [sliderRef]);

  React.useLayoutEffect(() => {
    if (!sliderRef) return;
    let rAFId: number = null;

    const handleScroll = (e: Event) => {
      cancelAnimationFrame(rAFId);
      rAFId = requestAnimationFrame(() => {
        const target = e.target as HTMLDivElement;
        const itemSize = target.scrollWidth / (images.length + 1 - itemSizePercent);
        const center = itemSize / itemSizePercent / 2;
        const offsetLeft = itemSize * ((1 - itemSizePercent) / 2);
        index.current = Math.floor((target.scrollLeft + center - offsetLeft) / itemSize);
        setActiveIndex(index.current);
      });
    };

    const updateCurrenctIndex = debounce(() => {
      goToIndex(index.current);
    }, 20);

    sliderRef.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('resize', updateCurrenctIndex, false);

    return () => {
      sliderRef.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateCurrenctIndex);
      cancelAnimationFrame(rAFId);
    };
  }, [sliderRef]);

  React.useEffect(() => {
    if (!interval) return;
    if (!sliderRef) return;

    sliderRef.addEventListener('pointerenter', stopLoop, false);
    sliderRef.addEventListener('pointerleave', startLoop, false);

    startLoop();

    return () => {
      sliderRef.removeEventListener('pointerenter', stopLoop);
      sliderRef.removeEventListener('pointerleave', startLoop);
      clearTimeout(timeoutId.current);
    };
  }, [sliderRef, interval]);

  if (!images || !images.length) return null;

  return (
    <section className={classNames('native-slider text-center', className)}>
      <div className="carousel carousel-scoll-snap">
        <div className="carousel-inner" ref={setSliderRef}>
          {images.map((image, index) => (
            <div
              key={index}
              className={classNames('carousel-item native-slider-item', {
                active: index === activeIndex,
                past: index < activeIndex,
                future: index > activeIndex,
              })}
              ref={itemRefs.current[index]}
            >
              <div className="carousel-item-content">
                {links && links.length && links[index] ? (
                  <Link to={links[index]}>
                    <PreviewImage
                      src={image}
                      previewSrc={previewImage}
                      alt={t(`PRODUCT.IMAGE_INDEX`, { index: index + 1 })}
                      className="img-fluid"
                      loading={loading}
                    />
                  </Link>
                ) : (
                  <PreviewImage
                    src={image}
                    previewSrc={previewImage}
                    alt={t(`PRODUCT.IMAGE_INDEX`, { index: index + 1 })}
                    className="img-fluid"
                    loading={loading}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        {/* <CarouselIndicators items={images} activeIndex={activeIndex} onClickHandler={goToIndex} /> */}
        {images.length > 1 && (
          <>
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
              // className={classNames({ invisible: activeIndex === 0 })}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
              // className={classNames({ invisible: activeIndex === images.length - 1 })}
            />
          </>
        )}
      </div>

      {thumbs && thumbs.length > 1 && (
        <nav className="carousel-indicators">
          {thumbs.map((thumb, index) => (
            <Button
              size="sm"
              key={index}
              className={classNames('p-0', { active: index === activeIndex }, thumbContainerClassName)}
              color="link"
              onClick={(e) => {
                goToIndex(index);
                e.preventDefault();
              }}
            >
              <PreviewImage
                src={thumb}
                previewSrc={previewImage}
                className={classNames('product-thumb', thumbClassName)}
                alt={t('PRODUCT.IMAGE_THUMB_INDEX', { index: index + 1 })}
                title={t('PRODUCT.IMAGE_THUMB_INDEX', { index: index + 1 })}
                width="50"
                height="50"
                loading={loading}
              />
            </Button>
          ))}
        </nav>
      )}
    </section>
  );
};

export default React.memo(NativeSlider);
