import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Container from 'reactstrap/lib/Container';

import { getTermsAndConditions } from '@ttstr/api/terms';
import { LoadingSpinner } from '@ttstr/components';

interface Props {
  showH1: boolean;
}

const TermsAndConditionsView: React.FC<Readonly<Props>> = ({ showH1 = false }) => {
  const { t } = useTranslation();
  const [termsAndConditions, setTermsAndConditions] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setTermsAndConditions(await getTermsAndConditions());
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) return <LoadingSpinner label={t(`LOADING.DATA`)} />;

  return (
    <article className="my-5">
      <Helmet>
        <title>{t('TERMS_AND_CONDITIONS.TITLE')}</title>
        <body className="page termsAndConditions-page" />
      </Helmet>

      <Container>
        {showH1 && <h1 className="text-center mb-4">{t('TERMS_AND_CONDITIONS.TITLE')}</h1>}
        <section dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
      </Container>
    </article>
  );
};

export default React.memo(TermsAndConditionsView);
