/**
 * Component for use in Product Detail Page.
 * 
 * Outputs Links to all the Brands that are listed in the Products product.brand_ids
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useProductDetails } from '@ttstr/components';

interface OwnProps {
  readonly brandIds: number[];
}

type Props = Readonly<OwnProps>;

const BrandPageLinks: React.FC<Props> = ({ brandIds }) => {
  const { t } = useTranslation();
  const { brands } = useProductDetails();


  if (!Array.isArray(brandIds) || !(brandIds.length > 0) || !brands) return null;

  return (
    <>
    {brandIds.map(brandId => 
      <Link key={brandId} to={`/brand/${brandId}`} className={`brand_link brand_link-${brandId} d-block`}>
        {t(`PRODUCT.MORE_FROM_BRAND_LINK`, { brand: brands[brandId]?.title })}
      </Link>
    )}
    </>
  )
}

export default React.memo(BrandPageLinks);
