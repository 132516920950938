import classNames from 'classnames';

import React from 'react';

import Button from 'reactstrap/lib/Button';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';

interface OwnProps extends React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> {
  nativeControls?: boolean;
  containerClassName?: string;
  controlColor?: string;
  controlOutline?: boolean;
  firstClickUnmutes?: boolean;
}

type Props = Readonly<OwnProps>;

const Video: React.FC<Props> = ({
  nativeControls = true,
  controls,
  controlColor,
  controlOutline,
  containerClassName,
  children,
  autoPlay,
  muted,
  preload,
  firstClickUnmutes = true,
  ...props
}) => {
  const videoContainerRef = React.useRef<HTMLDivElement>();
  const videoRef = React.useRef<HTMLVideoElement>();
  const isPristine = React.useRef(true);

  const [isPlaying, setIsPlaying] = React.useState(autoPlay);
  const [isMuted, setIsMuted] = React.useState(muted);
  const [awarePreload, setAwarePreload] = React.useState(preload);

  const togglePlay = React.useCallback(() => {
    isPristine.current = false;
    if (videoRef.current.paused) return videoRef.current.play();
    videoRef.current.pause();
  }, []);

  const toggleIsMuted = React.useCallback(() => {
    isPristine.current = false;
    setIsMuted((muted) => !muted);
  }, []);

  React.useEffect(() => {
    const setPlay = () => {
      if (videoRef.current) setIsPlaying(true);
    };
    const setPause = () => {
      if (videoRef.current) setIsPlaying(false);
    };
    const clickHandler = () => {
      if (firstClickUnmutes && isMuted && isPristine.current) return toggleIsMuted();
      togglePlay();
    };

    videoRef.current.addEventListener('play', setPlay);
    videoRef.current.addEventListener('pause', setPause);
    videoRef.current.addEventListener('click', clickHandler);

    return () => {
      if (!videoRef.current) return;
      videoRef.current.removeEventListener('play', setPlay);
      videoRef.current.removeEventListener('pause', setPause);
      videoRef.current.removeEventListener('click', clickHandler);
    };
  }, [firstClickUnmutes, toggleIsMuted, togglePlay]);

  React.useEffect(() => {
    if (!window.IntersectionObserver) return;
    if (!autoPlay) return;

    const handler: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.target !== videoRef.current) return;

        if (entry.isIntersecting) {
          videoRef.current.play();
          setAwarePreload(preload);
        } else {
          videoRef.current.pause();
          setAwarePreload('none');
        }
      });
    };

    const observer = new IntersectionObserver(handler, { root: videoContainerRef.current });
    observer.observe(videoRef.current);

    return () => observer.disconnect();
  }, [autoPlay]);

  return (
    <div className={classNames('video-container', containerClassName)} ref={videoContainerRef}>
      <video {...props} controls={nativeControls} muted={isMuted} preload={awarePreload} ref={videoRef}>
        {children}
      </video>
      {controls && (
        <ButtonGroup className="video-controls">
          <Button type="button" color={controlColor} outline={controlOutline} onClick={togglePlay}>
            <i className={classNames('fa', isPlaying ? 'fa-pause' : 'fa-play')} />
            <span className="sr-only" lang="en">
              {isPlaying ? 'pause' : 'play'}
            </span>
          </Button>
          <Button type="button" color={controlColor} outline={controlOutline} onClick={toggleIsMuted}>
            <i className={classNames('fa', isMuted ? 'fa-volume-mute' : 'fa-volume')} />
            <span className="sr-only" lang="en">
              {isMuted ? 'unmute' : 'mute'}
            </span>
          </Button>
        </ButtonGroup>
      )}
    </div>
  );
};

export default React.memo(Video);
