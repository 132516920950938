import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Col from 'reactstrap/lib/Col';
import FormGroup from 'reactstrap/lib/FormGroup';
import Row from 'reactstrap/lib/Row';

import { CartValidations, RegisterableCustomer, countriesThatRequireState } from '@ttstr/api';
import { EMAIL_PATTERN } from '@ttstr/utils';

import CountrySelect from '../Form/CountrySelect';
import FormField from '../Form/FormField';

type OwnProps =
  | {
      mode: 'create';
      showPassword?: boolean;
      onCountryChanged?(countryCode: string): void;
      hideCompany?: boolean;
      hideAddressAddition?: boolean;
      hideState?: boolean;
      overrideValidations?: CartValidations;
    }
  | {
      mode: 'modify';
      showPassword?: boolean;
      onCountryChanged?(countryCode: string): void;
      hideCompany?: boolean;
      hideAddressAddition?: boolean;
      hideState?: boolean;
      overrideValidations?: CartValidations;
    };

type Props = Readonly<OwnProps>;

const CustomerDataFields: React.FC<Props> = ({
  showPassword,
  mode,
  onCountryChanged,
  hideCompany,
  hideAddressAddition,
  hideState,
  overrideValidations,
}) => {
  const { t } = useTranslation();
  const { getValues, watch } = useFormContext<RegisterableCustomer>();

  const country = String(watch('country')).toUpperCase();

  return (
    <FormGroup>
      <Row>
        <Col>
          {/* First Name */}
          <FormField
            type="text"
            label={t('CUSTOMER.FIRSTNAME')}
            name="firstname"
            autoComplete="billing given-name"
            validationOptions={{ required: true }}
          />
        </Col>
        <Col>
          {/* Last Name */}
          <FormField
            type="text"
            label={t('CUSTOMER.LASTNAME')}
            name="lastname"
            autoComplete="billing family-name"
            validationOptions={{ required: true }}
          />
        </Col>
      </Row>
      {mode === 'create' && showPassword ? (
        <FormGroup tag="fieldset" className="form-credentials">
          <Row>
            <Col>
              <FormField
                type="email"
                label={t('CUSTOMER.EMAIL')}
                name="email"
                helpText={t('CUSTOMER.EMAIL_REGISTER_HELP')}
                autoComplete="billing email"
                validationOptions={{
                  required: true,
                  validate: {
                    'STRING.EMAIL': (value) => EMAIL_PATTERN.test(value),
                  },
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <legend>{t('CUSTOMER.CREDENTIALS')}</legend>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormField
                type="password"
                label={t('CUSTOMER.PASSWORD')}
                name="password"
                helpText={t('CUSTOMER.PASSWORD_HELP', { min: 6 })}
                autoComplete="new-password"
                formGroupClassName="mb-0"
                validationOptions={{
                  required: true,
                  minLength: 6,
                }}
              />
            </Col>
            <Col>
              <FormField
                type="password"
                label={t('CUSTOMER.PASSWORD_REPEAT')}
                name="passwordRepeat"
                autoComplete="new-password"
                formGroupClassName="mb-0"
                validationOptions={{
                  required: true,
                  minLength: 6,
                  validate: {
                    'CUSTOM.PASSWORDS_DONT_MATCH': (value) => String(value) === String(getValues().password),
                  },
                }}
              />
            </Col>
          </Row>
        </FormGroup>
      ) : (
        mode === 'create' &&
        overrideValidations?.email?.ask !== false && (
          <Row>
            <Col>
              <FormField
                type="email"
                label={t('CUSTOMER.EMAIL')}
                name="email"
                helpText={t('CUSTOMER.EMAIL_HELP')}
                autoComplete="billing email"
                validationOptions={{
                  required: true,
                  validate: {
                    'STRING.EMAIL': (value) => EMAIL_PATTERN.test(value),
                  },
                }}
              />
            </Col>
          </Row>
        )
      )}
      {mode === 'create' && showPassword ? (
        <Row>
          <Col>
            <legend>{t('CUSTOMER.ADDITIONAL')}</legend>
          </Col>
        </Row>
      ) : (
        mode === 'create' &&
        overrideValidations?.address?.ask !== false && (
          <Row>
            <Col>
              <legend>{t('CUSTOMER.ADDRESS')}</legend>
            </Col>
          </Row>
        )
      )}
      {!hideCompany && (
        <Row>
          <Col>
            {/* Company Name */}
            <FormField
              type="text"
              label={t('CUSTOMER.COMPANY_NAME')}
              name="companyname"
              autoComplete="billing organization"
              optional
              validationOptions={{ required: false }}
            />
          </Col>
        </Row>
      )}
      {overrideValidations?.address?.ask !== false && (
        <Row>
          <Col>
            {/* Street */}
            <FormField
              type="text"
              label={t('CUSTOMER.STREET')}
              name="street"
              autoComplete="billing street-address"
              validationOptions={{ required: true }}
            />
          </Col>
        </Row>
      )}
      {!hideAddressAddition && (
        <Row>
          <Col>
            {/* Address Addition */}
            <FormField
              type="text"
              label={t('CUSTOMER.ADDRESS_ADDITION')}
              name="address_addition"
              optional
              helpText={t('CUSTOMER.ADDRESS_ADDITION_HELP')}
              autoComplete="billing address-line3"
              validationOptions={{ required: false }}
            />
          </Col>
        </Row>
      )}
      {overrideValidations?.address?.ask !== false && (
        <Row>
          <Col xs="4">
            {/* Zip Code */}
            <FormField
              type="text"
              label={t('CUSTOMER.ZIP_CODE')}
              name="zip_code"
              autoComplete="billing postal-code"
              validationOptions={{ required: true }}
            />
          </Col>
          <Col>
            {/* City */}
            <FormField
              type="text"
              label={t('CUSTOMER.CITY')}
              name="city"
              autoComplete="billing address-level2"
              validationOptions={{ required: true }}
            />
          </Col>
        </Row>
      )}
      {!hideState && (
        <Row>
          <Col>
            {/* State */}
            <FormField
              type="text"
              label={t('CUSTOMER.STATE')}
              name="state"
              autoComplete="billing address-level1"
              optional={!countriesThatRequireState.includes(country)}
              validationOptions={{
                validate: {
                  required: (value) => {
                    if (!countriesThatRequireState.includes(String(getValues().country).toUpperCase())) return true;
                    return !!value.length;
                  },
                },
              }}
            />
          </Col>
        </Row>
      )}
      {overrideValidations?.address?.ask !== false && (
        <Row>
          <Col>
            {/* Country */}
            <CountrySelect
              name="country"
              autoComplete="billing country"
              onChange={onCountryChanged}
              validationOptions={{ required: true }}
            />
          </Col>
        </Row>
      )}
      {overrideValidations?.phone?.ask !== false && (
        <Row>
          <Col>
            {/* Phone number */}
            <FormField
              type="text"
              label={t('CUSTOMER.PHONE')}
              name="phone"
              // eslint-disable-next-line react/jsx-no-literals
              placeholder="+00 123 4567890"
              helpText={t('CUSTOMER.PHONE_HELP')}
              autoComplete="billing tel"
              optional={overrideValidations?.phone?.required !== true}
              validationOptions={{ required: overrideValidations?.phone?.required }}
            />
          </Col>
        </Row>
      )}
    </FormGroup>
  );
};

export default CustomerDataFields;
