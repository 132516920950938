import React from 'react';

interface OwnProps {
  value: number;
  trim?: boolean;
}

type Props = Readonly<OwnProps>;

const Countdown: React.FC<Props> = ({ value, trim }) => {
  const [time, setTime] = React.useState(value);
  const hours = Math.floor(time / (60 * 60));
  const minutes = Math.floor((time / 60) % 60);
  const seconds = Math.floor(time % 60);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTime((time) => time - 1);
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <React.Fragment>
      {[hours, minutes, seconds]
        .map((v) => v.toString().padStart(2, '0'))
        .join(':')
        .replace(trim ? /(00:)+/ : '', '')}
    </React.Fragment>
  );
};

export default React.memo(Countdown);
