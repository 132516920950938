/*
body tag will have data-scrolled if site is scrolled beneath the threshold.
Beneath the threshold it will also indicate an upwards scroll direction with data-scrollingUp.

Above the threshold it will have no data attributes (!)
*/


import React from 'react';

import { Helmet } from 'react-helmet';

import { useHistory, useLocation } from 'react-router-dom';

import { useShopConfig } from '../ShopConfig/ShopConfigContext';

interface OwnProps {
  threshold?: number;
}

type Props = React.PropsWithChildren<Readonly<OwnProps>>;

const ScrollToTop: React.FC<Props> = ({ threshold = 100, children }) => {
  const location = useLocation();
  const history = useHistory();
  const { canonicalUrl } = useShopConfig();
  const [scrolled, setScrolled] = React.useState(false);
  // scroll direction from https://stackoverflow.com/questions/62497110/detect-scroll-direction-in-react-js
  const [scrollDir, setScrollDir] = React.useState("scrolling-down");

  React.useEffect(() => {
    let lastScrollY = window.pageYOffset;
    let ticking = false;
    const threshold = 10;
  
    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;
  
      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? "scrolling-down" : "scrolling-up");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };
  
    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };
  
    window.addEventListener("scroll", onScroll);
  
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);

  React.useEffect(() => {
    const handler = () => {
      setScrolled(window.scrollY > threshold);
    };

    window.addEventListener('scroll', handler, { passive: true });
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);

  React.useEffect(() => {
    if (history.action === 'PUSH' && !location.hash) window.scrollTo(0, 0);
  }, [location.pathname, history.action, location.hash]);

  React.useEffect(() => {
    if (!location.hash) return;
    const navbarHeight = document.querySelector('#header .navbar')?.clientHeight ?? 0;
    const element = document.getElementById(location.hash.substring(1));

    if (element) {
      const offsetTop = element.offsetTop || (element.offsetParent as HTMLElement).offsetTop;
      const targetOffset = Math.max(0, offsetTop - navbarHeight);
      window.scrollTo(0, targetOffset);
    }
  }, [location.hash]);

  const createCanonical = canonicalUrl && location.pathname && location.pathname !== '/';

  return (
    <>
      <Helmet>
        {createCanonical && <link rel="canonical" href={canonicalUrl + location.pathname} />}
        {scrolled && scrollDir === 'scrolling-up' && <body data-scrolled={true} data-scrollingUp={true} />}
        {scrolled && scrollDir !== 'scrolling-up' && <body data-scrolled={true} />}
      </Helmet>
      {children}
    </>
  );
};

export default React.memo(ScrollToTop);
