/**
 * This component fills the Main Navigation with the categories.
 * Each category NavLink links to the categories CategoryView.
 * If there are Subcategories, they will be inside a Dropdown menu of their parent category.
 */

import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';

import { requestCategories } from '@ttstr/actions';
import { Category } from '@ttstr/api';
import { useActions } from '@ttstr/utils';
import { useProductDetails, useIntl } from '@ttstr/components';

import NavItem from 'reactstrap/lib/NavItem';
import NavLink from 'reactstrap/lib/NavLink';
// @ts-ignore
import UncontrolledDropdown from 'reactstrap/lib/UncontrolledDropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';

interface OwnProps {
  excludeCategories?: number | number[];
}

type Props = Readonly<OwnProps>;

const CategoryNavLinks: React.FC<Props> = ({ excludeCategories }) => {
  const { categories, categoriesReceived } = useProductDetails();
  const { requestCategories } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    requestCategories();
  }, [language, requestCategories]);

  const parentCategoryIds = React.useMemo(() => {
    return Object.values(categories)
      .filter((c) => c.parent_id !== null)
      .map((c) => c.parent_id);
  }, [categories, language]);

  if (!categoriesReceived) return null;

  return (
    <>
      {Object.values(categories)
        .filter((category) =>
          Array.isArray(excludeCategories)
            ? !excludeCategories.includes(category.id)
            : category.id !== excludeCategories
        )
        .sort((a, b) => a.position - b.position) // Backend Reihenfolge von links nach rechts
        .map((category: Category, i, categoryArr: Category[]) => {
          if (parentCategoryIds.includes(category.id)) {
            return (
              <UncontrolledDropdown key={`nav-category-${category.id}`} nav inNavbar>
                <DropdownToggle nav caret>
                  {category.title}
                </DropdownToggle>
                <DropdownMenu right>
                  {categoryArr
                    .filter((subCat) => subCat.parent_id === category.id)
                    .map((subCategory, index) => (
                      <DropdownItem
                        key={`dropdown-${category.id}-${subCategory.id}`}
                        tag={RouterNavLink}
                        to={`/category/${subCategory.id}`}
                        exact
                        className="dropdown-item"
                      >
                        {subCategory.title}
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            );
          } else if (!category.parent_id) {
            return (
              <NavItem key={`nav-category-${category.id}`}>
                <NavLink tag={RouterNavLink} to={`/category/${category.id}`} exact className="nav-link">
                  {category.title}
                </NavLink>
              </NavItem>
            );
          }
        })}
    </>
  );
};

const mapDispatchToProps = {
  requestCategories,
};

export default React.memo(CategoryNavLinks);
