import React from 'react';

import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';
import Button from 'reactstrap/lib/Button';
import Collapse from 'reactstrap/lib/Collapse';
import Form from 'reactstrap/lib/Form';
import Spinner from 'reactstrap/lib/Spinner';

import { replyToForm, RenderedFormContentrParagraphConfig } from '@ttstr/api';
import QuestionInput from '@ttstr/components/CatalogForm/QuestionInput';
import { useToggle } from '@ttstr/utils';

interface OwnProps {
  data: RenderedFormContentrParagraphConfig;
}

type Props = Readonly<OwnProps>;

const FormParagraph: React.FC<Props> = ({ data }) => {
  const { title, form, thankyou_message, button_label } = data;

  const { t } = useTranslation();
  const [showThankYou, toggleShowThankYou, setShowThankYou] = useToggle(false);
  const [errors, setErrors] = React.useState<{ [field: string]: string }>(null);

  const formContext = useForm();
  const { handleSubmit, formState, reset } = formContext;
  const { isSubmitting } = formState;

  const onSubmit: SubmitHandler<RenderedFormContentrParagraphConfig> = async (values /* , event */) => {
    const answers = Object.entries(values).map(([question_id, value]) => ({ question_id: Number(question_id), value }));
    try {
      await replyToForm(form.id, answers);
      reset();
      setShowThankYou(true);
    } catch (e) {
      setErrors(e.message);
    }
  };

  if (!form) return null;

  return (
    <section className="form-paragraph">
      <Collapse isOpen={!showThankYou}>
        <h5>{title || form.title}</h5>
        <FormProvider {...formContext}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {Object.entries(form.questions_attributes).map(([questionId, question]) => {
              return <QuestionInput key={questionId} answerId={question.id} question={question} />;
            })}
            {errors && <Alert color="danger">{t('FORM.GENERAL_ERROR')}</Alert>}
            <Button type="submit" color="primary" disabled={isSubmitting}>
              {button_label || t('FORM.SUBMIT')}
              <Spinner color="light" size="sm" className={isSubmitting ? 'ml-2 d-inline-block' : 'd-none'} />
            </Button>
          </Form>
        </FormProvider>
        {/* <pre><code>{JSON.stringify({form}, null, 2)}</code></pre> */}
      </Collapse>

      <Alert color="success" isOpen={showThankYou} toggle={toggleShowThankYou}>
        {thankyou_message || t('FORM.THANK_YOU')}
      </Alert>
    </section>
  );
};

export default React.memo(FormParagraph);
