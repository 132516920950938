/**
 * A simple Card Component for Masonry based Listings.
 * For use in simple Listings that want to be displayed as Masonry.
 *
 * Analog to he 'Sushi'-Cells for Tickets and Products, this Cell only features a Title and Image.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Card from 'reactstrap/lib/Card';
import PreviewImage from '@ttstr/components/Sushi/PreviewImage';
import CardImgOverlay from 'reactstrap/lib/CardImgOverlay';
import CardBody from 'reactstrap/lib/CardBody';
import CardTitle from 'reactstrap/lib/CardTitle';

import { CellProps } from './Cell';

interface Props extends CellProps {
  baseURLofDetailSite: string;
}

const PLACEHOLDER_IMAGE =
  'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22240%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20240%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1664f286b32%20text%20%7B%20fill%3Argba(255%2C255%2C255%2C.75)%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A12pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1664f286b32%22%3E%3Crect%20width%3D%22240%22%20height%3D%22180%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2289.2578125%22%20y%3D%2295.1%22%3E240x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E';

const SimpleCard: React.FC<Props> = ({ id, title, image, baseURLofDetailSite }) => {
  return (
    <Card
      id={`cell-${id}`}
      tag={Link}
      to={`${baseURLofDetailSite}/${id}`}
      className={classNames('maki-2020', 'masonry-cell')}
    >
      <div className={classNames('maki-img-container', 'cell-img-container')}>
        <PreviewImage
          className="card-img-top maki-img cell-img"
          previewSrc={image.productthumb.url}
          src={image.url || PLACEHOLDER_IMAGE}
          alt={title}
        />
      </div>

      <CardImgOverlay>
        <CardBody>
          <CardTitle tag="h2">
            <span className="title cell-title">{title}</span>
          </CardTitle>
        </CardBody>
      </CardImgOverlay>
    </Card>
  );
};

export default SimpleCard;
