import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Container from 'reactstrap/lib/Container';

import { getPrivacy } from '@ttstr/api/terms';
import { LoadingSpinner } from '@ttstr/components';

interface Props {
  showH1: boolean;
}

const PrivacyView: React.FC<Readonly<Props>> = ({ showH1 = false }) => {
  const { t } = useTranslation();
  const [privacy, setPrivacy] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setPrivacy(await getPrivacy());
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) return <LoadingSpinner label={t(`LOADING.DATA`)} />;

  return (
    <article className="my-5">
      <Helmet>
        <title>{t('PRIVACY.TITLE')}</title>
        <body className="page privacy-page" />
      </Helmet>

      <Container>
        {showH1 && <h1 className="text-center mb-4">{t('PRIVACY.TITLE')}</h1>}
        <section dangerouslySetInnerHTML={{ __html: privacy }} />
      </Container>
    </article>
  );
};

export default React.memo(PrivacyView);
