import React, { ReactNode } from 'react';

interface OwnProps {
  from: string;
  fallback?: ReactNode;
}

type Props = Readonly<React.PropsWithChildren<OwnProps>>;

/**
 * A Wrapper for Components that should only appear from a specific date and time.
 * You can pass a Component as prop that will be displayed outside the timeframe.
 *
 * @param from A datestring in the form 'Thu Jun 23 2022 18:47:30 GMT+0200'
 * @param fallback (optional) A Component that will be shown up until the date
 * defined in from. If left empty, nothing will be rendered up until the passed datetime.
 */
const Dated: React.FC<Props> = ({ from = '', fallback, children }) => {
  const golive = new Date(from);

  if (from && isNaN(golive.getTime())) {
    throw TypeError('from must be a valid datetime');
  }

  return <>{from && golive <= new Date() ? children : fallback || <></>}</>;
};

export default Dated;
