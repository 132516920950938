import React from 'react';

import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import AuthContext from '../Auth/AuthContext';
import LoadingSpinner from '../Loading/LoadingSpinner';

type Props = Readonly<RouteProps>;

const AuthRoute: React.FC<Props> = ({ ...props }) => {
  const location = useLocation();
  const { loggingIn, loggedIn, initialized } = React.useContext(AuthContext);

  if (!initialized && loggingIn) return <LoadingSpinner />;
  if (loggedIn) {
    return <Route {...props} />;
  }
  return <Redirect to={`/customer/login?success=${location.pathname}`} />;
};

export default React.memo(AuthRoute);
