import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { AppState } from '@ttstr/reducers';
import { receiveProducts } from '@ttstr/actions';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

import Alert from 'reactstrap/lib/Alert';
import Fade from 'reactstrap/lib/Fade';

import {
  Container,
  ContentrArea,
  ProductDetailHeader,
  useProductDetails,
  LoadingSpinner,
  useIntl,
  Masonry,
  Maki,
  Maki2020,
  MakiGIF,
  InfoSection,
} from '@ttstr/components';

import { Article } from '@ttstr/api';

import { useShopConfig } from '../ShopConfig/ShopConfigContext';

interface RouteParams {
  readonly id: string;
}

type Props = {
  productFilter?: (f: Article) => any;
};

// const CategoryView: React.FC = () => {
const CategoryView: React.FC<Props> = ({ productFilter = (f) => f }) => {

  const params = useParams<RouteParams>();
  const { t } = useTranslation();
  const { language } = useIntl();
  const {
    sites: { category },
  } = useShopConfig();

  const { categories, categoriesReceived } = useProductDetails();
  const { products, productsLoading, tickets, ticketsLoading } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);

  React.useEffect(() => {
    receiveProducts({ category_ids: Number(params.id) });
  }, [language, params.id, receiveProducts]);

  if (!categoriesReceived) {
    return <LoadingSpinner />;
  }

  return (
    <article className="mb-5 category-detail">
      <Helmet>
        <body className="category-detail-page" />
        <title>{categories[Number(params.id)]?.title}</title>
      </Helmet>

      {categories && (category?.showHeading ?? false) && (
        <Fade>
          {categories[Number(params.id)]?.hero_image?.url && (
            <ProductDetailHeader
              className="category-header"
              background={categories[Number(params.id)].hero_image.url}
              image={categories[Number(params.id)].hero_image.url}
            />
          )}
          <InfoSection
            title={categories[Number(params.id)].title}
            subtitle={categories[Number(params.id)].subtitle}
            imageUrl={categories[Number(params.id)].image.url}
            description={categories[Number(params.id)].description}
          />
        </Fade>
      )}

      <Container>
        <ContentrArea id="category-before-listing" />
      </Container>

      <Container className="py-5">
        {/* <h2 className="text-center mb-4 d-none">{t(`CATEGORY.PRODUCT_TITLE`)}</h2> */}
        {productsLoading || ticketsLoading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : products.length === 0 && tickets.length === 0 ? ( // if no products
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="product-listing"
            products={products
              // filter by the category we're in
              .filter((p) => p.category_ids.includes(categories[Number(params.id)].id))
              .filter(productFilter)
              // sort by start date or alphabetically (language sensitive)
              .sort((a, b) => category?.sortItemsByDate === true ? (+a.valid_start_on - +b.valid_start_on) : new Intl.Collator(language).compare(a.title, b.title))
              // sort the highest feature to top of list
              .sort((a, b) => b.priority - a.priority)}
            type={
              category?.makiType === 'Maki'
                ? Maki
                : category?.makiType === 'Maki2020'
                ? Maki2020
                : category?.makiType === 'MakiGIF'
                ? MakiGIF
                : category?.makiType === 'list'
                ? 'list'
                : null
            }
            filters={category?.showFilter ? category.showFilter : 'off'}
            showSupertitle={category?.showMakiSupertitle ?? false}
            showSubtitle
            showTags={category?.showMakiTags ?? false}
            showFormerPrice
          />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products } = state.Products;
  const productsLoading = state.Products.loading;
  const { tickets } = state.Tickets;
  const ticketsLoading = state.Tickets.loading;
  return {
    products,
    productsLoading,
    tickets,
    ticketsLoading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default CategoryView;
