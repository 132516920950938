import React from 'react';

import memoize from 'lodash.memoize';

import { useIntl } from './IntlContext';

interface Props {
  value: number;
}

const defaultOptions: Intl.NumberFormatOptions = {
  style: 'decimal',
};

const NumberComponent: React.FC<Props> = ({ value }) => {
  const { language } = useIntl();

  return <>{getFormatter(language).format(value)}</>;
};

export const getFormatter = memoize((language: string) => {
  return new Intl.NumberFormat(language, defaultOptions);
});

export default React.memo(NumberComponent);
