import classNames from 'classnames';

import React from 'react';

import { useTranslation } from 'react-i18next';

import { Container } from '@ttstr/components';

const defaultContainerClassName = 'd-flex justify-content-center px-0 px-sm-3';

interface OwnProps {
  className?: string;
  image?: string;
  background?: string;
  container?: React.ComponentClass;
  containerClassName?: string;
}

type Props = Readonly<React.PropsWithChildren<OwnProps>>;

const ProductDetailHeader: React.FC<Props> = ({
  image,
  background,
  className,
  container = Container,
  containerClassName = defaultContainerClassName,
  children,
}) => {
  const { t } = useTranslation();

  const ContainerTag = container;

  return (
    <section
      className={classNames(`blurry-background-image`, className)}
      style={{ ['--image' as string]: `url(${background || image})` }}
    >
      <ContainerTag className={containerClassName}>
        {image && (
          <img
            src={image}
            className={`product-header-image`}
            alt={t('PRODUCT.IMAGE')}
            itemProp="image"
            loading="lazy"
          />
        )}
        {children}
      </ContainerTag>
    </section>
  );
};

export default React.memo(ProductDetailHeader);
