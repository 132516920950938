import React from 'react';

interface OwnProps {
  data: {
    body?: string;
  };
}

type Props = Readonly<OwnProps>;

const WysiwygParagraph: React.FC<Props> = ({ data }) => {
  if (!data) return null;
  if (!data.body) return null;

  return <div dangerouslySetInnerHTML={{ __html: data.body }} />;
};

export default React.memo(WysiwygParagraph);
