import React from 'react';

// @ts-ignore
import Dropdown from 'reactstrap/lib/UncontrolledDropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import Fade from 'reactstrap/lib/Fade';

import { useTranslation } from 'react-i18next';

import {
  ContentrParagraphType,
  WysiwygContentrParagraph,
  FormContentrParagraph,
  HeroImgContentrParagraph,
  AnyContentrParagraph,
} from '@ttstr/api/contentr';

import { Loading } from '..';
import { useContentr } from './ContentrContext';
import ContentrParagraph from './ContentrParagraph';

const ContentrEditParagraph = React.lazy(() => import('@ttstr/components/Contentr/ContentrEditParagraph'));

interface OwnProps {
  id: string;
}

type Props = Readonly<OwnProps>;

const ContentrArea: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation();
  const { editMode, paragraphs, onAreaChange, addAreaToCurrentView, removeAreaFromCurrentView } = useContentr(id);

  const onParagraphChange = React.useCallback(
    (index: number) => {
      return (newParagraph: AnyContentrParagraph, modified = true) => {
        const modifiedParagraphs = [...paragraphs];
        modifiedParagraphs[index] = newParagraph;
        onAreaChange(id, modifiedParagraphs, modified);
      };
    },
    [paragraphs, onAreaChange, id]
  );

  const addAnyParagraphToArea = (newParagraph: AnyContentrParagraph) => {
    const modifiedParagraphs = [...paragraphs, newParagraph];

    onAreaChange(id, modifiedParagraphs);
  };

  const addWysiwygParagraph = () => {
    const paragraphBlueprint: WysiwygContentrParagraph = {
      type: ContentrParagraphType.WysiwygParagraph,
      rendered_data: null,
      unpublished_data: {
        body: '',
      },
    };

    addAnyParagraphToArea(paragraphBlueprint);
  };

  const addFormParagraph = () => {
    const paragraphBlueprint: FormContentrParagraph = {
      type: ContentrParagraphType.FormParagraph,
      rendered_data: null,
      unpublished_data: {
        form_id: null,
      },
    };

    addAnyParagraphToArea(paragraphBlueprint);
  };

  const addHeroImgParagraph = () => {
    const paragraphBlueprint: HeroImgContentrParagraph = {
      type: ContentrParagraphType.HeroImgParagraph,
      rendered_data: null,
      unpublished_data: {
        img: '',
        alt_text: '',
      },
    };

    addAnyParagraphToArea(paragraphBlueprint);
  };

  React.useEffect(() => {
    addAreaToCurrentView(id);

    return () => removeAreaFromCurrentView(id);
  }, [id, addAreaToCurrentView, removeAreaFromCurrentView]);

  return (
    <section className="contentr-area" id={id}>
      {editMode ? (
        <Loading className="my-4" color="secondary" label={t('LOADING.DATA')}>
          {paragraphs.map((paragraph, index) => (
            <Fade in key={paragraph.id || index}>
              <ContentrEditParagraph paragraph={paragraph} onParagraphChange={onParagraphChange(index)} />
            </Fade>
          ))}
        </Loading>
      ) : (
        paragraphs.map((paragraph, index) => <ContentrParagraph key={paragraph.id || index} paragraph={paragraph} />)
      )}

      {editMode && (
        <div className="p-3">
          <Dropdown>
            <DropdownToggle block size="lg">
              <span className="sr-only">{t('CONTENTR.AREA.ADD')}</span>
              <i className="fa fa-plus" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={addFormParagraph}>
                <i className="fad fa-ballot-check text-muted mr-2" />
                {' Form Paragraph'}
              </DropdownItem>
              <DropdownItem onClick={addWysiwygParagraph}>
                <i className="fad fa-typewriter text-muted mr-2" />
                {' WYSIWYG Paragraph'}
              </DropdownItem>
              <DropdownItem onClick={addHeroImgParagraph}>
                <i className="fad fa-image text-muted mr-2" />
                {' Hero-Image Paragraph'}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      )}
    </section>
  );
};

export default React.memo(ContentrArea);
