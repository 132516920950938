import classNames from 'classnames';

import React from 'react';

import Fade from 'reactstrap/lib/Fade';
import Spinner from 'reactstrap/lib/Spinner';

export interface OwnProps {
  className?: string;
  label?: string;
  type?: string;
  color?: string;
}

type Props = Readonly<OwnProps>;

const LoadingSpinner: React.FC<Props> = ({ className, label, color = 'primary', type = 'grow' }) => {
  return (
    <Fade className="loading-spinner">
      <span className="loading-spinner-content">
        <Spinner color={color} type={type} className={classNames(className, { 'mr-3': !!label })} /> {label}
      </span>
    </Fade>
  );
};

export default React.memo(LoadingSpinner);
