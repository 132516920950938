import React from 'react';
import dot from 'dot-object';
import { Helmet } from 'react-helmet';
import { useIntl } from '@ttstr/components/Intl/IntlContext';

import { AppState } from '@ttstr/reducers';
import { Ticket } from '@ttstr/api/products';
import { getLocationFromProduct } from '@ttstr/reducers/locations';

import { ProductDetailsContext, useProductDetails, useProductDetailLink, useShopConfig } from '@ttstr/components';
import { useShallowEqualSelector } from '@ttstr/utils';

interface Props {
  ticket: Ticket;
  dontChangeMetaDescription?: boolean;
}

/**
 * Check for Googles guidelines
 * https://developers.google.com/search/docs/advanced/structured-data/event#technical-guidelines
 */

interface AddressSchema {
  '@type': 'PostalAddress';
  streetAddress: string;
  postalCode: string;
  addressLocality: string;
}

interface LocationSchema {
  '@type': 'Place';
  name?: string;
  latitude?: string | number;
  longitude?: string | number;
  address: Partial<AddressSchema>;
}

interface OffersSchema {
  '@type': 'Offer';
  availability: 'https://schema.org/InStock' | 'https://schema.org/SoldOut';
  price?: string;
  priceCurrency?: string;
  validFrom?: string;
  validThrough?: string;
  url?: string;
}

interface PerformerSchema {
  '@type': string;
  name: string;
}

interface OrganiserSchema {
  '@type': 'Organization';
  name?: string;
  url?: string;
  address?: AddressSchema;
}

interface EventSchema {
  '@context': 'https://schema.org';
  '@type': 'Event';
  name: string;
  description?: string;
  startDate: string;
  endDate: string;
  doorTime?: string;
  eventStatus?: string;
  eventAttendanceMode:
    | 'https://schema.org/OfflineEventAttendanceMode'
    | 'https://schema.org/OnlineEventAttendanceMode'
    | 'https://schema.org/MixedEventAttendanceMode';
  previousStartDate?: string;
  image: string | string[];
  location: LocationSchema;
  offers: OffersSchema;
  performer?: PerformerSchema | PerformerSchema[];
  organizer: OrganiserSchema;
}

const TicketMarkup: React.FC<Props> = ({ ticket, dontChangeMetaDescription = false }) => {
  const { locale } = useIntl();
  const { canonicalUrl } = useShopConfig();
  const productDetailLink = useProductDetailLink();
  const { currency } = useShallowEqualSelector(mapStateToProps);
  const { locations } = React.useContext(ProductDetailsContext);
  const location = getLocationFromProduct(locations, ticket);
  const { brands, merchants } = useProductDetails();

  const images = React.useMemo(() => [ticket.hero_image.url, ...ticket.gallery_image_urls.map((i) => i.original)], [
    ticket,
  ]);

  const jsonLd: Partial<EventSchema> = {
    '@context': 'https://schema.org',
    '@type': 'Event',
    eventStatus: 'https://schema.org/EventScheduled',
    eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
    location: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
      },
    },
    offers: {
      '@type': 'Offer',
      availability: 'https://schema.org/InStock',
    },
    organizer: {
      '@type': 'Organization',
    },
  };

  // Event Attributes
  dot.copy('title', 'name', ticket, jsonLd);
  dot.copy('description', 'description', ticket, jsonLd);
  dot.copy('valid_start_on', 'startDate', ticket, jsonLd); // only Date: 2020-07-31
  dot.copy('valid_end_on', 'endDate', ticket, jsonLd); // should be end Date and Time
  dot.copy('time_open', 'doorTime', ticket, jsonLd); // only Time
  // TODO 'eventStatus'
  // TODO 'eventAttendenceMode'
  // TODO? 'previousStartDate'

  // Images
  jsonLd.image = images.length ? images.filter((i) => i !== null).map((imageUrl) => canonicalUrl + imageUrl) : [];

  // Location Attributes
  dot.copy('title', 'location.name', location, jsonLd);
  dot.copy('latitude', 'location.latitude', location, jsonLd);
  dot.copy('longitude', 'location.longitude', location, jsonLd);
  dot.copy('street', 'location.address.streetAddress', location, jsonLd);
  dot.copy('city', 'location.address.addressLocality', location, jsonLd);
  dot.copy('zip_code', 'location.address.postalCode', location, jsonLd);

  // Offer Attributes
  dot.copy('min_price', 'offers.price', ticket, jsonLd);
  dot.copy('available_start_at', 'offers.validFrom', ticket, jsonLd);
  dot.copy('available_end_at', 'offers.validThrough', ticket, jsonLd);
  jsonLd.offers.url = productDetailLink(ticket, { canonicalUrl });
  jsonLd.offers.priceCurrency = currency ?? 'EUR';
  if (ticket.sold_out_status === 'sold_out') jsonLd.offers.availability = 'https://schema.org/SoldOut'; // overrides the default InStock

  // Performer Attributes
  if (!Array.isArray(ticket.brand_ids)) dot.copy('title', 'performer.name', brands[ticket.brand_ids], jsonLd);
  else {
    const performers = ticket.brand_ids.map((id) => {
      return {
        '@type': 'PerformingGroup',
        name: brands[id]?.title,
      };
    });
    jsonLd.performer = performers;
  }

  // Organizer
  dot.copy('title', 'organizer.name', merchants[ticket.merchant_id], jsonLd);
  dot.copy('website', 'organizer.url', merchants[ticket.merchant_id], jsonLd);

  const pageTitle = ticket.title + ' in ' + location.city + ' - ' + new Date(ticket.valid_start_on).toLocaleDateString(locale.code, { month: 'short', day: 'numeric' }) + ', ' + location.title

  return (
    <Helmet>
      <title>
        {pageTitle}
      </title>
      
      {!dontChangeMetaDescription && ticket.description && <meta name="description" content={ticket.description} />}

      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={ticket.description} />
      <meta property="og:url" content={productDetailLink(ticket, { canonicalUrl })} />
      <meta property="og:image" content={canonicalUrl + ticket.image.url} />

      <script type="application/ld+json">{`${JSON.stringify(jsonLd)}`}</script>
      <link rel="canonical" href={productDetailLink(ticket, { canonicalUrl })} />
    </Helmet>
  );
};

const mapStateToProps = (state: AppState) => {
  const { settings } = state.Settings;
  return {
    currency: settings.config_currency,
  };
};

export default React.memo(TicketMarkup);
