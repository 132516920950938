import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useActions, useMounted, useShallowEqualSelector } from '@ttstr/utils';
import { LoadingSpinner } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { getProductById } from '@ttstr/reducers/productDetails';
import { receiveProduct } from '@ttstr/actions';

interface RouteParams {
  readonly id: string;
}

const mapDispatchToProps = {
  receiveProduct,
};

const RedirectToProductDetailUrlWithSlug: React.FC = () => {
  const isMounted = useMounted();
  const { t } = useTranslation();
  const params = useParams<RouteParams>();
  const [initialized, setInitialized] = React.useState(false);
  const { receiveProduct } = useActions(mapDispatchToProps);
  const { product, loading } = useShallowEqualSelector((state: AppState) => {
    const { loading } = state.ProductDetails;
    const product = getProductById(state, Number(params.id));
    return {
      loading,
      product,
    };
  });

  React.useEffect(() => {
    (async () => {
      try {
        setInitialized(false);
        await receiveProduct(Number(params.id));
      } finally {
        if (isMounted.current) setInitialized(true);
      }
    })();
  }, [params.id]);

  if (!initialized || (!product && loading)) {
    return <LoadingSpinner label={t(`LOADING.PRODUCT`)} />;
  }

  // if there is no product, return to homepage
  if (initialized && !product) {
    return <Redirect to="/" />;
  }

  return <Redirect to={`/product/${params.id}/${product.ptitle}`} />;
};

export default React.memo(RedirectToProductDetailUrlWithSlug);
