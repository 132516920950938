import React from 'react';
import ReactSelect, { OptionsType } from 'react-select';
import { Articles } from '@ttstr/api';
import { useIntl } from '@ttstr/components';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

type SorterOption = 'alphabetical' | 'date';
export type SorterOptions = SorterOption[];

interface OptionType {
  label: string;
  id: number;
}

interface OwnProps {
  products: Articles;
  onUpdatedSorters: React.Dispatch<any>;
  sorters: SorterOptions;
}

const allOptions: OptionsType<OptionType> = [
  {
    label: 'Alphabetisch aufsteigend',
    id: 1,
  },
  {
    label: 'Alphabetisch absteigend',
    id: 2,
  },
  {
    label: 'Datum aufsteigend',
    id: 3,
  },
  {
    label: 'Datum absteigend',
    id: 4,
  },
];

type Props = Readonly<OwnProps>;

/**
 * TODO:
 * - changes in sort order don't bubble up to masonry
 * - translations
 */

const MasonrySorter: React.FC<Props> = ({ products, onUpdatedSorters, sorters }) => {
  const { language } = useIntl();
  const [sortId, setSortId] = React.useState(0);

  React.useEffect(() => {
    switch (sortId) {
      case 1:
        console.log('sorting a->z');
        onUpdatedSorters(products.sort((a, b) => new Intl.Collator(language).compare(a.title, b.title)));
        break;
      case 2:
        console.log('sorting z->a');
        onUpdatedSorters(products.sort((a, b) => new Intl.Collator(language).compare(b.title, a.title)));
        break;
      default:
        return;
    }
  }, [sortId, products, language]);

  if (sorters === undefined) return null;

  let availableOptions = [];
  if (sorters.includes('alphabetical')) availableOptions.push(1, 2);
  if (sorters.includes('date')) availableOptions.push(3, 4);
  availableOptions = availableOptions.map((n) => allOptions[n - 1]);

  return (
    <Row>
      <Col>
        <ReactSelect
          options={availableOptions}
          id="sort_direction_selector"
          name="sort_direction_selector"
          defaultValue={availableOptions[0]}
          onChange={(option) => setSortId(option.id)}
        />
      </Col>
    </Row>
  );
};

export default React.memo(MasonrySorter);
