import classNames from 'classnames';

import React from 'react';

import Button from 'reactstrap/lib/Button';
import Spinner from 'reactstrap/lib/Spinner';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation, Prompt } from 'react-router-dom';

import { requestContentrForScope, updateContentrForScope, ContentrAreas, AnyContentrParagraph } from '@ttstr/api';
import { useToggle } from '@ttstr/utils';

import { useIntl } from '../Intl/IntlContext';

async function getContentrForScope(scope: string) {
  return await requestContentrForScope(scope);
}

async function setContentrForScope(scope: string, areas: ContentrAreas) {
  return await updateContentrForScope(scope, areas);
}

interface ContextProps {
  areas?: ContentrAreas;
  editMode: boolean;
  onAreaChange(areaId: string, paragraphs: AnyContentrParagraph[], modified?: boolean): void;
  addAreaToCurrentView(areaId: string): void;
  removeAreaFromCurrentView(areaId: string): void;
}

const defaultValue: ContextProps = {
  areas: {},
  editMode: false,
  onAreaChange: () => null,
  addAreaToCurrentView: () => null,
  removeAreaFromCurrentView: () => null,
};

const ContentrContext = React.createContext<ContextProps>(defaultValue);

type ContentrProviderProps = React.PropsWithChildren<{
  disabled?: boolean;
}>;

const ContentrProviderComponent: React.FC<ContentrProviderProps> = ({ disabled = false, children }) => {
  const { t } = useTranslation();
  const { language } = useIntl();
  const { pathname } = useLocation();
  const [canEdit, setCanEdit] = React.useState(false);
  const [areas, setAreas] = React.useState<ContentrAreas>({});
  const [pendingChanges, setPendingChanges] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [editMode, toggleEditMode] = useToggle(false);
  const [areasInCurrentView, setAreasInCurrentView] = React.useState<string[]>([]);

  const addAreaToCurrentView = React.useCallback(
    (areaId: string) => {
      setAreasInCurrentView((previous) => {
        if (previous.includes(areaId)) return previous;
        return [...previous, areaId];
      });
    },
    [setAreasInCurrentView]
  );

  const removeAreaFromCurrentView = React.useCallback(
    (areaId: string) => {
      setAreasInCurrentView((previous) => {
        if (!previous.includes(areaId)) return previous;
        return previous.filter((id) => id !== areaId);
      });
    },
    [setAreasInCurrentView]
  );

  const onAreaChange = React.useCallback((areaId: string, paragraphs: AnyContentrParagraph[], modified = true) => {
    setAreas((previousAreas) => {
      return {
        ...previousAreas,
        [areaId]: paragraphs,
      };
    });
    if (modified) setPendingChanges(true);
  }, []);

  const saveChanges = React.useCallback(async () => {
    try {
      setSaving(true);
      const { contentr_areas, can_edit } = await setContentrForScope(pathname, areas);
      setAreas(contentr_areas);
      setCanEdit(can_edit);
      setPendingChanges(false);
    } finally {
      setSaving(false);
    }
  }, [pathname, areas]);

  const contextState = {
    areas,
    editMode,
    onAreaChange,
    addAreaToCurrentView,
    removeAreaFromCurrentView,
  };

  // whenever the language or path changes ask for new contentr content
  React.useEffect(() => {
    if (disabled) return;
    if (!pathname) return;
    if (pathname === '/') return;

    setPendingChanges(false);

    (async () => {
      const { contentr_areas, can_edit } = await getContentrForScope(pathname);
      setAreas(contentr_areas);
      setCanEdit(can_edit);
    })();
  }, [disabled, language, pathname]);

  /**
   * on top of the onRouteChange <Prompt> we need to inform the user when he tries to close the tab
   * @see https://stackoverflow.com/a/49163569/622041
   */
  React.useEffect(() => {
    window.onbeforeunload = pendingChanges ? () => true : undefined;

    return () => (window.onbeforeunload = undefined);
  }, [pendingChanges]);

  if (disabled) {
    return <ContentrContext.Provider value={contextState}>{children}</ContentrContext.Provider>;
  }

  return (
    <>
      <Prompt message={t('CONTENTR.ON_BEFORE_LEAVE_MESSAGE')} when={pendingChanges} />

      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/html-has-lang */}
        <html className={classNames('contentr', { 'highlight-areas': editMode })} />
      </Helmet>

      <ContentrContext.Provider value={contextState}>{children}</ContentrContext.Provider>

      {canEdit && (
        <div className={classNames('contentr-bar', { editing: editMode })}>
          <span
            dangerouslySetInnerHTML={{ __html: t('CONTENTR.ITEMS_IN_VIEW', { count: areasInCurrentView.length }) }}
          />
          {pendingChanges && (
            <span>
              {t('CONTENTR.HAS_UNSAVED_CHANGES')}{' '}
              <Button color="dark" size="sm" onClick={saveChanges} disabled={saving}>
                <i className="fa fa-save" /> {t('CONTENTR.SAVE_CHANGES')}
                {saving && <Spinner className="ml-2" size="sm" />}
              </Button>
            </span>
          )}
          <div>
            <a href="/admin" className="btn btn-light btn-sm">
              <i className="fas fa-tachometer-alt-slow" /> {t('CONTENTR.GOTO_ADMIN_DASHBOARD')}
            </a>{' '}
            <Button size="sm" onClick={toggleEditMode}>
              <i className={`fas fa-${editMode ? 'sign-out' : 'file-edit'}`} />{' '}
              {t(editMode ? 'CONTENTR.LEAVE_EDIT_MODE' : 'CONTENTR.ENTER_EDIT_MODE')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
export const ContentrProvider = React.memo(ContentrProviderComponent);

/**
 * uses the Contentr-Context provided by ContentrProvider;
 */
export const useContentr = (areaId: string) => {
  const { areas, ...context } = React.useContext(ContentrContext);

  return {
    paragraphs: areas?.[areaId] ?? [],
    ...context,
  };
};

export default ContentrContext;
