import classNames from 'classnames';

import React from 'react';

import { useMounted } from '@ttstr/utils/index';

function preloadImage(src: string, alt: string = null) {
  return new Promise<Event>((resolve, reject) => {
    const image = document.createElement('img');
    image.addEventListener('load', resolve);
    image.addEventListener('error', reject);
    image.setAttribute('alt', alt);
    image.setAttribute('src', src);
  });
}

interface OwnProps {
  previewSrc: string;
}

type Props = Readonly<OwnProps & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>>;

const PreviewImage: React.FC<Props> = ({ className, previewSrc, src, alt, loading = 'lazy', ...props }) => {
  const isMounted = useMounted();
  const finalImageTransition = React.useRef<HTMLDivElement>();
  const [loaded, setLoaded] = React.useState(false);
  const [completed, setCompleted] = React.useState(false);
  const [finalSrc, setFinalSrc] = React.useState(null);

  const handleTransitionEnd = (e: TransitionEvent) => {
    if (e.propertyName !== 'opacity') return;
    if (e.target !== finalImageTransition.current) return;

    setCompleted(true);
  };

  React.useEffect(() => {
    (async () => {
      setFinalSrc(previewSrc || src); // show preview-src initially and fallback to final src
      await preloadImage(src, alt);
      if (!isMounted.current) return;
      setLoaded(true);
      setFinalSrc(src);
    })();
  }, [src, previewSrc, loading]);

  React.useEffect(() => {
    if (finalImageTransition.current) {
      finalImageTransition.current.addEventListener('transitionend', handleTransitionEnd, false);
      finalImageTransition.current.addEventListener('transitioncancel', handleTransitionEnd, false);
    }
    return () => {
      if (!finalImageTransition.current) return;
      finalImageTransition.current.removeEventListener('transitionend', handleTransitionEnd);
      finalImageTransition.current.removeEventListener('transitioncancel', handleTransitionEnd);
    };
  }, []);

  if (completed || !previewSrc) {
    return <img {...props} className={className} src={finalSrc} alt={alt} loading={previewSrc ? null : loading} />;
  }

  return (
    <div className="preview-image-container">
      <img
        {...props}
        className={classNames(className, 'preview-image', { loading: !loaded })}
        src={previewSrc}
        alt={alt}
        loading={loading}
      />
      <div ref={finalImageTransition} className={classNames('final-image-transition fade', { show: loaded })}>
        <img {...props} className={className} src={finalSrc} alt={alt} />
      </div>
    </div>
  );
};

export default React.memo(PreviewImage);
