import React from 'react';
import { Container, ContentrArea } from '@ttstr/components';

const PlainContentrPage: React.FC = () => {
  return (
    <article className="page my-5">
      <Container>
        <ContentrArea id="main" />
      </Container>
    </article>
  );
};

export default PlainContentrPage;
