/**
 * Dies ist ein Nachbau des OG Masonry das geweitet wurde als nur für Produkte
 *
 * Statt einem Array `products` gibt man ihm einen Array `cellData`
 * Über dieses Array wird gemapped und jeder Datensatz einem CellElement übergeben.
 * WIP!
 *
 * TODO:
 * - verschiedene CellElemente: momentan nur SimpleCard; kann über cellComponentType gesteuert werden.
 * - react-flip-toolkit scheint keinen Stagger Effekt zu machen; Hat jedoch positiven Einfluss auf das
 *   rendern. Evtl ersetzen mit zB Framer?
 * - Maki2020 Nachbau
 * - Nigiri (Row-View) Nachbau
 * - Filter hinzufügen
 *
 */

import classNames from 'classnames';
import React from 'react';

import { HandleEnterUpdateDelete } from 'flip-toolkit/lib/types';
import { Flipper, Flipped, spring } from 'react-flip-toolkit';

import { CellProps } from '../Listing/Cells/Cell';
import { SimpleCard } from '..';

interface OwnProps {
  id?: string;
  baseURLofDetailSite: string;
  cellData: CellProps[];
  cellComponentType: React.ComponentType<CellProps>;
}
type Props = Readonly<OwnProps>;

const staggerConfig = {
  default: {
    speed: 0.5,
  },
};

const handleEnterUpdateDelete: HandleEnterUpdateDelete = ({
  animateEnteringElements,
  animateExitingElements,
  animateFlippedElements,
  hideEnteringElements,
}) => {
  animateEnteringElements();
  animateExitingElements();
  animateFlippedElements();
  hideEnteringElements();
};

const onAppear = (el: HTMLElement, index: number) => {
  el.style.zIndex = '0';
  spring({
    config: 'gentle',
    values: {
      opacity: [0, 1],
      translateY: [-10, 0],
    },
    onUpdate: ({ opacity, translateY }: any) => {
      el.style.opacity = opacity;
      el.style.transform = `translateY(${translateY}px)`;
    },
    delay: i * 25,
  });
};

const onExit = (el: HTMLElement, index: number, removeElement: () => void) => {
  removeElement();

  el.style.zIndex = '-1';
  spring({
    config: 'gentle',
    values: {
      opacity: [1, 0],
      scale: [1, 0.5],
      translateY: [0, 10],
    },
    onUpdate: ({ opacity, scale, translateY }: any) => {
      el.style.opacity = opacity;
      el.style.transform = `translateY(${translateY}px) scale(${scale})`;
    },
    delay: index * 25,
    onComplete: removeElement,
  });
};

const Masonry2: React.FC<Props> = ({ id, baseURLofDetailSite, cellData, cellComponentType }) => {
  const flipKey = React.useMemo(() => JSON.stringify(cellData.map((d) => d.id)), []);

  return (
    <section id={id} itemScope itemType="http://schema.org/ItemList">
      <Flipper
        className={classNames('masonry', 'row')}
        flipKey={flipKey}
        spring="stiff"
        staggerConfig={staggerConfig}
        handleEnterUpdateDelete={handleEnterUpdateDelete}
      >
        {cellData.map((thing) => (
          <Flipped flipId={String(thing.id)} key={thing.id} onAppear={onAppear} onExit={onExit}>
            <article
              key={`cell-${thing.id}`}
              id={`article-${thing.id}`}
              className={classNames('sushi-container', 'maki-container', 'col-md-6 col-lg-4')}
            >
              <SimpleCard
                id={thing.id}
                title={thing.title}
                image={thing.image}
                baseURLofDetailSite={baseURLofDetailSite}
              />
            </article>
          </Flipped>
        ))}
      </Flipper>
    </section>
  );
};

export default Masonry2;
