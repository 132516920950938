import classNames from 'classnames';

import React from 'react';

import ListGroup from 'reactstrap/lib/ListGroup';

interface OwnProps {
  className?: string;
  hasErrors?: boolean;
}

type Props = Readonly<React.PropsWithChildren<OwnProps>>;

const RadioList: React.FC<Props> = ({ className, hasErrors, children }) => {
  return (
    <ListGroup className={classNames('radio-list-group', className, { 'is-invalid': hasErrors })}>{children}</ListGroup>
  );
};

export default React.memo(RadioList);
