import React from 'react';

import { useTranslation } from 'react-i18next';

import Col from 'reactstrap/lib/Col';
import FormGroup from 'reactstrap/lib/FormGroup';
import Row from 'reactstrap/lib/Row';

import { EMAIL_PATTERN } from '@ttstr/utils';

import FormField from '../Form/FormField';
import CountrySelect from '../Form/CountrySelect';
import { useShopConfig } from '../ShopConfig/ShopConfigContext';

const NewsletterDataFields: React.FC = () => {
  const { t } = useTranslation();
  const shopConfig = useShopConfig();

  /**
   * TODO: check what fields are required in backend under 'Spezialeinstellungen' and create form fields for them.
   * (more info in api/newsletter)
   */

  return (
    <FormGroup>
      <Row>
        <Col>
          {/* First Name */}
          <FormField
            type="text"
            label={t('CUSTOMER.FIRSTNAME')}
            name="firstname"
            autoComplete="billing given-name"
            validationOptions={{ required: true }}
          />
        </Col>
        <Col>
          {/* Last Name */}
          <FormField
            type="text"
            label={t('CUSTOMER.LASTNAME')}
            name="lastname"
            autoComplete="billing family-name"
            validationOptions={{ required: true }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {/* Email */}
          <FormField
            type="email"
            label={t('CUSTOMER.EMAIL')}
            name="email"
            helpText=""
            autoComplete="billing email"
            validationOptions={{
              required: true,
              validate: {
                'STRING.EMAIL': (value) => EMAIL_PATTERN.test(value),
              },
            }}
          />
        </Col>
      </Row>

      {!shopConfig.newsletterOverrideValidations?.country?.disable && (
        <Row>
          <Col>
            {/* Country */}
            <CountrySelect name="country" autoComplete="billing country" validationOptions={{ required: true }} />
          </Col>
        </Row>
      )}
    </FormGroup>
  );
};

export default React.memo(NewsletterDataFields);
