import classNames from 'classnames';

import React from 'react';

import { FormProvider, useForm, useFormContext, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from 'reactstrap/lib/Button';
import CustomInput from 'reactstrap/lib/CustomInput';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import ListGroup from 'reactstrap/lib/ListGroup';
import ListGroupItem from 'reactstrap/lib/ListGroupItem';
import Spinner from 'reactstrap/lib/Spinner';

import { addToCart, receiveProduct } from '@ttstr/actions';
import { Product, Variant, Variants } from '@ttstr/api';
import { useActions, useToggle } from '@ttstr/utils';
import { useProductDetails } from '@ttstr/components/ProductDetail';

import NumberInput from '../Input/NumberInput';
import Currency from '../Intl/Currency';
import DateComponent from '../Intl/DateComponent';
import FormField from '../Form/FormField';
import { useShopConfig } from '../ShopConfig/ShopConfigContext';
import VariantStockStatus, { mapStockStatusToColor } from './VariantStockStatus';
import VariantsNotAvailable, { mapSoldOutStatusToReason } from './VariantsNotAvailable';
import PriceRange from './PriceRange';
import PackageModal from './PackageModal';

const defaultCartIcon = <i className="fal fa-shopping-bag" />;

interface OwnProps {
  product: Product;
  cartIcon?: React.ReactNode;
  className?: string;
  hidePrice?: boolean;
  showFormerPrice?: boolean;
  color?: string;
  outline?: boolean;
  variantChooserForm?: React.ComponentType<VariantChooserFormProps>;
  addToCartTrackingHook?: (v: Variant, q: number) => void | null;
}

type Props = Readonly<OwnProps>;

export interface VariantInformation {
  variantId: string;
  quantity: string;
}

const VariantChooser: React.FC<Props> = ({
  product,
  cartIcon = defaultCartIcon,
  className,
  variantChooserForm = ProductVariantChooserForm,
  hidePrice,
  showFormerPrice = false,
  color = 'secondary',
  outline = false,
  addToCartTrackingHook = null,
}) => {
  const { sites } = useShopConfig();
  const { addToCart, receiveProduct } = useActions(mapDispatchToProps);
  const [showPackageModal, togglePackageModal] = useToggle(false);
  const variants: Variants = React.useMemo(() => {
    if (Array.isArray(product.online_variants_attributes)) {
      const productAndRabattCategories = product.online_variants_attributes;

      // look for Rabattkategorie in each Productkategorie and add them as variant
      product.online_variants_attributes.forEach((a) => {
        if (a.alternatives_attributes) {
          a.alternatives_attributes.forEach((b) => {
            productAndRabattCategories.push(b);
          });
        }
      });

      return productAndRabattCategories;
    }

    return Object.values(product.online_variants_attributes);
  }, [product]);

  const [firstVariant] = variants;
  const firstVariantId = firstVariant?.id;
  const defaultValues = React.useMemo<VariantInformation>(() => {
    return variants.length === 1
      ? {
          variantId: String(firstVariantId),
          quantity: firstVariant?.remaining_for_online > 0 ? String(firstVariant.min_per_order) : '0',
        }
      : {
          variantId: '',
          quantity: '0',
        };
  }, [variants, firstVariant, firstVariantId]);

  const formContext = useForm<VariantInformation>({
    mode: 'onChange',
    defaultValues,
  });
  const { reset, handleSubmit, getValues, setValue, watch } = formContext;
  const selectedVariantId = watch('variantId');
  const selectedQuantity = watch('quantity');

  const handleVariantChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const nextVariant = variants.find((v) => v.id === Number(e.target.value));
      const nextMinPerOrder = nextVariant?.min_per_order ?? 1;
      const nextRemainingForOnline = nextVariant?.remaining_for_online ?? 0;
      const nextQuantity = nextRemainingForOnline > 0 ? Math.min(nextMinPerOrder, nextRemainingForOnline) : 0;

      setValue('quantity', String(nextQuantity), { shouldValidate: true });
    },
    [variants, getValues, setValue]
  );

  const selectedVariant = React.useMemo(() => variants.find((v) => v.id === Number(selectedVariantId)), [
    variants,
    selectedVariantId,
  ]);

  const minPerOrder = React.useMemo(() => selectedVariant?.min_per_order ?? 1, [selectedVariant]);
  const remainingForOnline = React.useMemo(() => selectedVariant?.remaining_for_online ?? 0, [selectedVariant]);

  const onSubmit: SubmitHandler<VariantInformation> = async ({ variantId, quantity }) => {
    const currentVariant = variants.find((v) => Number(v.id) === Number(variantId));
    if (currentVariant?.package_membership_groups_attributes) {
      // Cancel submission and require more package-related information
      togglePackageModal();
      return;
    }

    await addToCart(Number(variantId), Number(quantity));
    if (addToCartTrackingHook) addToCartTrackingHook(currentVariant, Number(quantity));

    if (!product.is_option) await receiveProduct(Number(product.id)); // Update product availability afterwards
    reset();
  };

  if (product.status !== 'active') return <VariantsNotAvailable />;
  if (product.sold_out_status && product.sold_out_status_details.message) {
    return <VariantsNotAvailable reasonPlainText={product.sold_out_status_details.message} />;
  }
  if (product.sold_out_status && product.sold_out_status !== 'rescheduled_active') {
    return <VariantsNotAvailable reason={mapSoldOutStatusToReason(product.sold_out_status)} />;
  }
  if (product.available_end_at && product.available_end_at <= new Date()) {
    return <VariantsNotAvailable reason="EXPIRED" />;
  }
  if (!variants.length) return <VariantsNotAvailable reason="SOLD_OUT" />;

  const FormTag = variantChooserForm;

  return (
    <FormProvider {...formContext}>
      <Form onSubmit={handleSubmit(onSubmit)} className={classNames('variant-chooser', className)}>
        <link itemProp="availability" href="http://schema.org/InStock" />

        {showFormerPrice && product.former_price && (
          <small className="former-price mr-2 text-decoration-line-through text-danger">
            <Currency value={product.former_price} />
          </small>
        )}

        {!hidePrice && variantChooserForm === ProductVariantChooserForm && (
          <PriceRange product={product} selectedVariant={selectedVariant} className="variant-chooser-price" />
        )}

        {sites?.productDetail?.showStockStatusMessages && selectedVariant && (
          <VariantStockStatus
            stockStatus={mapStockStatusToColor(selectedVariant.stock_status)}
            message={selectedVariant.stock_message}
          />
        )}

        <fieldset>
          <FormTag
            product={product}
            variants={variants}
            selectedVariant={selectedVariant}
            selectedVariantId={selectedVariantId}
            selectedQuantity={selectedQuantity}
            minPerOrder={minPerOrder}
            remainingForOnline={remainingForOnline}
            handleVariantChange={handleVariantChange}
            cartIcon={cartIcon}
            color={color}
            outline={outline}
            hidePrice={hidePrice}
          />
        </fieldset>
      </Form>

      <PackageModal
        product={product}
        cartIcon={cartIcon}
        isOpen={showPackageModal}
        toggle={togglePackageModal}
        reset={reset}
      />
    </FormProvider>
  );
};

interface VariantChooserFormProps {
  product: Product;
  variants: Variants;
  selectedVariant: Variant;
  selectedVariantId: string;
  selectedQuantity: string;
  minPerOrder: number;
  remainingForOnline: number;
  handleVariantChange(e: React.ChangeEvent<HTMLInputElement>): Promise<void>;
  cartIcon: React.ReactNode;
  color?: string;
  outline?: boolean;
  hidePrice?: boolean;
}

export const TicketVariantChooserForm = React.memo<VariantChooserFormProps>(
  ({
    product,
    variants,
    selectedVariant,
    selectedVariantId,
    selectedQuantity,
    minPerOrder,
    remainingForOnline,
    handleVariantChange,
    hidePrice,
    cartIcon,
    color,
    outline,
  }) => {
    const { t } = useTranslation();
    const { register, formState } = useFormContext<VariantInformation>();
    const { isSubmitting, isValid } = formState;

    const isPackage = Boolean(selectedVariant?.package_membership_groups_attributes);

    return (
      <ListGroup tag="div" className={classNames('radio-list-group mb-4')}>
        <ListGroupItem color="light" tag="div" className="p-4">
          {/* eslint-disable-next-line react/jsx-no-literals */}
          <h5 className="mb-0">1. {t('VARIANT.CHOOSE')}</h5>
        </ListGroupItem>
        {variants.map((v) => {
          const active = String(v.id) === selectedVariantId;
          return (
            <ListGroupItem
              key={v.id}
              color="secondary"
              className="p-4"
              tag="label"
              htmlFor={`variant_${v.id}`}
              active={active}
              action
            >
              <div className="media align-items-center">
                <CustomInput
                  id={`variant_${v.id}`}
                  type="radio"
                  name="variantId"
                  value={v.id}
                  onChange={handleVariantChange}
                  className="mr-1"
                  innerRef={register({ required: true }) as any}
                  readOnly={v.remaining_for_online === 0}
                />
                <div className="media-body">
                  <h5 className="my-0 d-flex justify-content-between align-items-start">
                    <span className="text-break">
                      {v.title || product.title}
                      {v.remaining_for_online === 0 && (
                        <span className="text-warning ml-2">{t('VARIANT.NOT_AVAILABLE.GENERAL')}</span>
                      )}
                    </span>
                    {!hidePrice && (
                      <span className="ml-1 badge badge-secondary badge-pill">
                        <Currency value={Number(v.price)} />
                      </span>
                    )}
                  </h5>
                </div>
              </div>
            </ListGroupItem>
          );
        })}

        <ListGroupItem color="light" tag="div" className="p-4 d-flex justify-content-between align-items-center">
          {/* eslint-disable-next-line react/jsx-no-literals */}
          <h5 className="mb-0">2. {t('VARIANT.CHOOSE_AMOUNT')}</h5>
          <Label htmlFor="quantity" className="sr-only">
            {t(`PRODUCT.QUANTITY`)}
          </Label>
          <NumberInput
            color={color}
            outline={outline}
            className="d-inline-block"
            size="lg"
            id="quantity"
            name="quantity"
            min={minPerOrder}
            max={remainingForOnline}
            readOnly={!isValid}
            innerRef={register({
              min: minPerOrder,
              required: true,
            })}
          />
        </ListGroupItem>

        <ListGroupItem color="light" className="p-4 text-right">
          <Button
            type="submit"
            className="add-product text-nowrap"
            color="primary"
            size="lg"
            title={t(`PRODUCT.ADD_TO_CART`)}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <Spinner color="white" size="sm" />
            ) : (
              <>
                {cartIcon}
                <span className="ml-2">
                  {t(isPackage ? 'PRODUCT.CONFIGURE_PACKAGE.TITLE' : 'PRODUCT.ADD_TO_CART', {
                    count: Number(selectedQuantity),
                  })}
                </span>
              </>
            )}
          </Button>
        </ListGroupItem>
      </ListGroup>
    );
  }
);

export const ProductVariantChooserForm = React.memo<VariantChooserFormProps>(
  ({
    product,
    variants,
    selectedVariant,
    selectedVariantId,
    selectedQuantity,
    minPerOrder,
    remainingForOnline,
    handleVariantChange,
    cartIcon,
    color,
    outline,
  }) => {
    const { t } = useTranslation();
    const { register, formState } = useFormContext<VariantInformation>();
    const { isSubmitting, isValid } = formState;

    const isPackage = Boolean(selectedVariant?.package_membership_groups_attributes);

    return (
      <FormGroup row className="align-items-start mb-0">
        {variants.length === 1 ? (
          <FormGroup className="variant-chooser-select col d-flex align-self-center align-items-center">
            {selectedVariant?.title || product.title}
            <Input type="hidden" name="variantId" id="variantId" innerRef={register({ required: true })} />
          </FormGroup>
        ) : (
          <FormField
            type="select"
            name="variantId"
            id="variantId"
            label={t('PRODUCT.VARIANT')}
            srOnlyLabel
            formGroupClassName="variant-chooser-select col"
            validationOptions={{ required: true }}
            onChange={handleVariantChange}
          >
            <option aria-selected={String(selectedVariantId) === ''} value="">
              {t(`PRODUCT.PLEASE_CHOOSE`)}
            </option>
            {variants.map((v) => (
              <option
                key={v.id}
                value={v.id}
                aria-selected={String(v.id) === selectedVariantId}
                disabled={!v.remaining_for_online}
              >
                {v.title}
              </option>
            ))}
          </FormField>
        )}
        <FormGroup className="variant-chooser-quantity col">
          <Label htmlFor="quantity" className="sr-only">
            {t(`PRODUCT.QUANTITY`)}
          </Label>
          <NumberInput
            color={color}
            outline={outline}
            id="quantity"
            name="quantity"
            min={minPerOrder}
            max={remainingForOnline}
            readOnly={!isValid}
            innerRef={register({
              min: minPerOrder,
              required: true,
            })}
          />
        </FormGroup>
        <FormGroup className="variant-chooser-cta col-sm-12 col-md mb-0">
          <div className="text-nowrap">
            <Button
              type="submit"
              className="add-product text-nowrap"
              color="primary"
              title={t(`PRODUCT.ADD_TO_CART`)}
              block
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner color="white" size="sm" />
              ) : (
                <>
                  {cartIcon}
                  <span className="ml-2">
                    {t(isPackage ? 'PRODUCT.CONFIGURE_PACKAGE.TITLE' : 'PRODUCT.ADD_TO_CART', {
                      count: Number(selectedQuantity),
                    })}
                  </span>
                </>
              )}
            </Button>
          </div>
        </FormGroup>
      </FormGroup>
    );
  }
);

export const MediaVariantChooserForm: React.FC<VariantChooserFormProps> = ({
  product,
  variants,
  selectedVariant,
  selectedVariantId,
  selectedQuantity,
  hidePrice,
  minPerOrder,
  remainingForOnline,
  handleVariantChange,
  cartIcon,
  color,
  outline,
}) => {
  const { t } = useTranslation();
  const { register, formState } = useFormContext<VariantInformation>();
  const { isSubmitting, isValid } = formState;

  const { locations } = useProductDetails();

  const isPackage = Boolean(selectedVariant?.package_membership_groups_attributes);

  return (
    <div className="variant-chooser-media">
      <Input type="hidden" name="quantity" id="quantity" innerRef={register({ required: true })} />
      <div className="media align-items-center">
        <img src={product.image.productthumb.url} alt={product.title} className="product-thumb mr-3" loading="lazy" />
        <div className="media-body d-flex justify-content-between align-items-center">
          <h5 className="m-0">
            {product.type === 'Ticket' ? (
              <>
                <strong>{product.title}</strong>
                <p className="date-and-location">
                  <DateComponent value={product.valid_start_on} />
                  {' — ' + locations[product.location_id].title}
                </p>
              </>
            ) : (
              <strong>{product.title}</strong>
            )}
            {variants.length === 1 ? (
              <div className="variant-chooser-select">
                {selectedVariant?.title || product.title}
                <Input type="hidden" name="variantId" id="variantId" innerRef={register({ required: true })} />
              </div>
            ) : (
              <FormField
                type="select"
                name="variantId"
                id="variantId"
                label={t('PRODUCT.VARIANT')}
                srOnlyLabel
                formGroupClassName="variant-chooser-select mb-0"
                validationOptions={{ required: true }}
                onChange={handleVariantChange}
              >
                <option aria-selected={String(selectedVariantId) === ''} value="">
                  {t(`PRODUCT.PLEASE_CHOOSE`)}
                </option>
                {variants.map((v) => (
                  <option
                    key={v.id}
                    value={v.id}
                    aria-selected={String(v.id) === selectedVariantId}
                    disabled={!v.remaining_for_online}
                  >
                    {v.title}
                  </option>
                ))}
              </FormField>
            )}
          </h5>
          <div className="d-flex align-items-center media-body-cta-container">
            {!hidePrice && (
              <span className="mx-3">
                <PriceRange
                  tag="span"
                  plusSignBeforePrice
                  product={product}
                  selectedVariant={selectedVariant}
                  className="variant-chooser-price d-inline"
                />
              </span>
            )}
            <Button
              type="submit"
              className="add-product text-nowrap"
              color="primary"
              title={t(`PRODUCT.ADD_TO_CART`)}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner color="white" size="sm" />
              ) : (
                <>
                  {cartIcon}
                  <span className="ml-2 d-none d-lg-inline">
                    {t(isPackage ? 'PRODUCT.CONFIGURE_PACKAGE.TITLE' : 'PRODUCT.ADD_TO_CART', {
                      count: Number(selectedQuantity),
                    })}
                  </span>
                </>
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  addToCart,
  receiveProduct,
};

export default React.memo(VariantChooser);
