import classNames from 'classnames';
import React from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Spinner from 'reactstrap/lib/Spinner';

import { requestCreateCustomer } from '@ttstr/actions/customer';
import { RegisterableCustomer, CartValidations } from '@ttstr/api';
import { useActions } from '@ttstr/utils';

import FormField from '../Form/FormField';
import ValidationAlert from '../Form/ValidationAlert';
import BirthdayPicker from '../Input/BirthdayPicker';
import CustomerDataFields from './CustomerDataFields';

interface RegisterCustomer extends RegisterableCustomer {
  tnc: boolean;
}

const defaultInitialValues: RegisterCustomer = {
  address_addition: '',
  allow_promotion: true,
  birthday_on: null,
  city: '',
  companyname: '',
  country: '',
  email: '',
  fax: '',
  firstname: '',
  lastname: '',
  mobile: '',
  password: '',
  passwordRepeat: '',
  phone: '',
  state: '',
  street: '',
  title: '',
  tnc: false,
  zip_code: '',
};

interface OwnProps {
  onRegistered?(): void;
  showAllowPromotion?: boolean;
  dataFieldValidation?: CartValidations;
}

type Props = Readonly<OwnProps>;

const CustomerRegister: React.FC<Props> = ({ onRegistered, showAllowPromotion = false, dataFieldValidation }) => {
  const [signupError, setSignupError] = React.useState<string>(null);
  const { t } = useTranslation();
  const { requestCreateCustomer } = useActions(mapDispatchToProps);
  const formContext = useForm<RegisterCustomer>({
    mode: 'onChange',
    defaultValues: defaultInitialValues,
  });
  const { register, handleSubmit, reset, setValue, formState, watch } = formContext;
  const { isValid, isSubmitting } = formState;

  const onSubmit = async (values: RegisterCustomer) => {
    try {
      setSignupError(null);
      const { passwordRepeat, ...registerValues } = values;
      await requestCreateCustomer(registerValues);
      if (onRegistered) onRegistered();
    } catch (error) {
      setSignupError(error.message);
    }
  };
  const handleReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    reset();
  };

  const birthdayOn = watch('birthday_on');

  return (
    <section>
      <FormProvider {...formContext}>
        <Form onSubmit={handleSubmit(onSubmit)} onReset={handleReset}>
          <fieldset disabled={isSubmitting}>
            <FormGroup tag="fieldset">
              <legend>{t(`CUSTOMER.DATA`)}</legend>
              <CustomerDataFields mode="create" showPassword overrideValidations={dataFieldValidation} />
              <FormGroup className="mt-0 mb-5">
                <Label htmlFor="birthday_on">
                  {t(`CUSTOMER.DOB`)} <small className="text-muted">(optional)</small>
                </Label>
                <BirthdayPicker
                  name="birthday_on"
                  id="birthday_on"
                  value={birthdayOn}
                  onChange={(value) => setValue('birthday_on', value, { shouldValidate: true })}
                  ref={() => register({ name: 'birthday_on' })}
                />
              </FormGroup>
              {showAllowPromotion && (
                <FormField
                  type="checkbox"
                  name="allow_promotion"
                  id="allow_promotion"
                  label={t(`CHECKOUT.ALLOW_PROMOTION`)}
                  optional
                  innerRef={register}
                />
              )}
              <FormField
                type="checkbox"
                name="tnc"
                id="tnc"
                /* eslint-disable react/jsx-no-literals */
                label={
                  <Trans i18nKey="REGISTER.TNC">
                    I hereby confirm that I have read and accept the{' '}
                    <Link to="/privacy" target="_blank">
                      Privacy Statement
                    </Link>
                    . I also confirm that I have read and accepted the{' '}
                    <Link to="/terms" target="_blank">
                      Terms and Conditions
                    </Link>
                    .
                  </Trans>
                }
                /* eslint-enable */
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <hr />

            <ValidationAlert className="mb-2" />
            {signupError && <p className="text-danger">{t('CUSTOMER.SIGNUP.FAILED')}</p>}
            <FormGroup className="d-flex flex-column flex-md-row-reverse justify-content-md-between align-items-md-center">
              <Button type="submit" color="primary" size="lg" className="mb-3" disabled={!isValid}>
                {t('CUSTOMER.REGISTER')}
                <span className={classNames('spinner-wrapper', { invisible: !isSubmitting })}>
                  <Spinner color="primary" size="sm" />
                </span>
              </Button>
              <Button type="reset" color="link" className="mb-3">
                {t('CUSTOMER.RESET')}
              </Button>
            </FormGroup>
          </fieldset>
        </Form>
      </FormProvider>
    </section>
  );
};

const mapDispatchToProps = {
  requestCreateCustomer,
};

export default React.memo(CustomerRegister);
