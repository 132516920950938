import classNames from 'classnames';

import React from 'react';

import { useFormContext, FieldError, RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CustomInput from 'reactstrap/lib/CustomInput';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import FormGroup from 'reactstrap/lib/FormGroup';
import FormText from 'reactstrap/lib/FormText';
import Input, { InputProps } from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';

export interface FormFieldProps extends Omit<InputProps, 'invalid'> {
  innerRef?: React.Ref<HTMLInputElement>;
  id?: string;
  label?: React.ReactNode;
  helpText?: string;
  optional?: boolean;
  formGroupClassName?: string;
  srOnlyLabel?: boolean;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  name?: string;
  validationOptions?: RegisterOptions;
}

const FormField: React.FC<FormFieldProps> = ({
  label,
  helpText,
  optional,
  formGroupClassName,
  srOnlyLabel,
  innerRef,
  validationOptions,
  ...props
}) => {
  const { t } = useTranslation();
  const formContext = useFormContext();
  const { register, errors } = formContext || {}; // safety fallback in case there is no context
  const id = props.id || props.name;
  const nameSegments = props.name?.split('.') || [];
  const currentErrors = !nameSegments.length
    ? null
    : (nameSegments.reduce((path, segment) => path?.[segment] ?? null, errors) as FieldError);
  const hasErrors = Boolean(currentErrors);
  const isCheck = ['checkbox', 'radio'].includes(props.type);

  if (innerRef && validationOptions) {
    throw new Error("There can't be both an `innerRef` and `validationOptions` as attributes. Choose either one.");
  }

  const validationFeedback = (
    <FormFeedback valid={!hasErrors}>
      {(hasErrors && currentErrors?.message) ||
        (currentErrors?.type && t(`FORM.VALIDATION.${currentErrors?.type?.toUpperCase()}`, validationOptions))}
    </FormFeedback>
  );

  return (
    <FormGroup className={formGroupClassName}>
      {isCheck ? (
        <CustomInput
          {...props}
          invalid={hasErrors}
          type={props.type === 'radio' ? 'radio' : 'checkbox'}
          id={id}
          label={label}
          innerRef={validationOptions ? register(validationOptions) : innerRef}
        >
          {optional ? <small className="text-muted"> {t('FORM.OPTIONAL')}</small> : null}
          {validationFeedback}
        </CustomInput>
      ) : props.type === 'select' ? (
        <>
          <Label htmlFor={id} className={classNames({ 'sr-only': srOnlyLabel })}>
            {label}
            {optional ? <small className="text-muted"> {t('FORM.OPTIONAL')}</small> : null}
          </Label>
          <CustomInput
            {...props}
            type="select"
            id={id}
            className={classNames({
              'is-invalid': hasErrors,
            })}
            innerRef={validationOptions ? register(validationOptions) : innerRef}
          >
            {props.placeholder && (
              <option disabled value="">
                {props.placeholder}
              </option>
            )}
            {props.children}
          </CustomInput>
          {validationFeedback}
        </>
      ) : (
        <>
          <Label htmlFor={id} className={classNames({ 'sr-only': srOnlyLabel })}>
            {label}
            {optional ? <small className="text-muted"> {t('FORM.OPTIONAL')}</small> : null}
          </Label>
          <Input
            {...props}
            id={id}
            className={classNames({
              'is-invalid': hasErrors,
            })}
            innerRef={validationOptions ? register(validationOptions) : innerRef}
          >
            {props.children}
          </Input>
          {validationFeedback}
        </>
      )}
      {helpText && <FormText>{helpText}</FormText>}
    </FormGroup>
  );
};

export default React.memo(FormField);
