/**
 * Info: Schwarzes Label ist nicht erreichbar.
 * Hat eine Variante ein schwarzes Label ist es vom Backend als nicht verfügbar gekennzeichnet.
 * In dem Fall ist es im VariantListing nicht auswählbar.
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { StockStatus } from '@ttstr/api';

export type StockStatusColor = 'WHITE' | 'GREEN' | 'YELLOW' | 'RED' | 'BLACK';

export function mapStockStatusToColor(status: StockStatus): StockStatusColor {
  switch (status) {
    case 0:
      return 'WHITE';
    case 1:
      return 'GREEN';
    case 2:
      return 'YELLOW';
    case 3:
      return 'RED';
    case 4:
      return 'BLACK';

    default:
      return 'WHITE';
  }
}

interface OwnProps {
  stockStatus: StockStatusColor;
  message?: string;
  /**
   * @description alle Farben bei denen eine Info angezeigt werden soll. 'GREEN', 'YELLOW', 'RED' oder 'BLACK.
   * @example showMessageForColors={['YELLOW', 'RED']}
   * @default ['YELLOW', 'RED', 'BLACK']
   */
  showMessageForColors?: StockStatusColor[];
}
type Props = Readonly<OwnProps>;
/*
  Schema.org ItemAvailability Statuses:

  BackOrder
  Discontinued
  InStock
  InStoreOnly
  LimitedAvailability
  OnlineOnly
  OutOfStock
  PreOrder
  PreSale
  SoldOut
 */
const VariantStockStatus: React.FC<Props> = ({
  stockStatus,
  message,
  showMessageForColors = ['GREEN', 'YELLOW', 'RED', 'BLACK'],
}) => {
  const { t } = useTranslation();

  if (stockStatus === 'WHITE') return null;

  return (
    <>
      {stockStatus === 'GREEN' && <link itemProp="availability" href="http://schema.org/InStock" />}
      {stockStatus === 'YELLOW' && <link itemProp="availability" href="http://schema.org/LimitedAvailability" />}
      {stockStatus === 'RED' && <link itemProp="availability" href="http://schema.org/BackOrder" />}
      {showMessageForColors.includes(stockStatus) && (
        <div className="mb-3">
          <span className={classNames(`stock_status-${stockStatus.toLowerCase()}`, 'badge stock_status')}>
            <i className="fas fa-traffic-light"></i> {t(`STOCK.STATUS.${stockStatus}`)}{' '}
          </span>
          {message && <span className="stock_message mt-1 d-block small">{message}</span>}
        </div>
      )}
    </>
  );
};

export default React.memo(VariantStockStatus);
