import React from 'react';

import { Switch, Redirect, Route } from 'react-router-dom';
import { useShopConfig } from '@ttstr/components';

import TicketsView from '@ttstr/components/Views/TicketsView';
import ProductsView from '../Views/ProductsView';

const Routes: React.FC = () => {
  const { routes, sites } = useShopConfig();

  return (
    <Switch>
      {routes.homepath && routes.homepath !== '/' && <Redirect exact from="/" to={routes.homepath} />}

      <Route
        exact
        path="/tickets"
        render={() => (
          <TicketsView
            ticketFilter={sites.tickets.ticketFilter}
            showHeading={sites.tickets.showHeading}
            showLocation={sites.tickets.showLocation}
            showCity={sites.tickets.showCity}
            showTitle={sites.tickets.showTitle}
            showSupertitle={sites.tickets.showSupertitle}
            showSubtitle={sites.tickets.showSubtitle}
            showBrand={sites.tickets.showBrand}
            showCategories={sites.tickets.showCategories}
            showTags={sites.tickets.showTags}
          />
        )}
      />

      <Route
        exact
        path="/products"
        render={() => (
          <ProductsView
            productFilter={sites.products.productFilter}
            showHeading={sites.products.showHeading}
            showLocation={sites.products.showLocation}
            showCity={sites.products.showCity}
            showTitle={sites.products.showTitle}
            showSupertitle={sites.products.showSupertitle}
            showSubtitle={sites.products.showSubtitle}
            showBrand={sites.products.showBrand}
            showCategories={sites.products.showCategories}
            showTags={sites.products.showTags}
          />
        )}
      />
    </Switch>
  );
};

export default React.memo(Routes);
