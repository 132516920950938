import classNames from 'classnames';

import React from 'react';

import Button from 'reactstrap/lib/Button';
import Table from 'reactstrap/lib/Table';
import Form from 'reactstrap/lib/Form';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Spinner from 'reactstrap/lib/Spinner';
import Progress from 'reactstrap/lib/Progress';

import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';

import { CatalogFormAnswers } from '@ttstr/api';
import { updateCartItemReplies } from '@ttstr/actions';
import { AppState } from '@ttstr/reducers';
import { useShallowEqualSelector, useActions } from '@ttstr/utils';

import CartListingRow from '@ttstr/components/ShoppingCart/CartListingRow';
import CatalogForm from '@ttstr/components/CatalogForm/CatalogForm';

type FormValues = { [key: string]: string };

/**
 * This modal acts as a (FIFO) queue which only shows one form for the first to-be-answered replies
 */
const ProductFormModal: React.FC = () => {
  const { t } = useTranslation();
  const { items, hasUnsubmittedReplies } = useShallowEqualSelector(mapStateToProps);
  const { updateCartItemReplies } = useActions(mapDispatchToProps);

  // FIFO queue
  const firstItemWithUnsubmittedReplies = React.useMemo(() => {
    return items.find((i) => i.replies.length && i.replies.some((r) => !r.submitted));
  }, [items]);

  const firstUnsubmittedReplyIndex = React.useMemo(() => {
    return firstItemWithUnsubmittedReplies?.replies.findIndex((r) => !r.submitted);
  }, [firstItemWithUnsubmittedReplies]);
  const repliesCount = firstItemWithUnsubmittedReplies?.replies.length;
  const firstUnsubmittedReply = firstItemWithUnsubmittedReplies?.replies[firstUnsubmittedReplyIndex];

  const formContext = useForm<FormValues>({
    mode: 'onChange',
  });
  const { handleSubmit, formState } = formContext;
  const { isValid, isSubmitting } = formState;

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    const answers: CatalogFormAnswers = Object.entries(values).map(([answerId, value]) => ({
      id: Number(answerId),
      value,
    }));
    const replies = [
      {
        id: firstUnsubmittedReply.id,
        answers,
      },
    ];

    await updateCartItemReplies(firstItemWithUnsubmittedReplies.id, replies);
  };

  return (
    <Modal isOpen={hasUnsubmittedReplies} centered>
      <FormProvider {...formContext}>
        <Form onSubmit={handleSubmit(onSubmit)} className="modal-content">
          <ModalHeader>{t('CART.UNANSWERED_REPLIES.TITLE')}</ModalHeader>
          {firstItemWithUnsubmittedReplies && firstUnsubmittedReply && (
            <>
              <ModalBody>
                <p>{t('CART.UNANSWERED_REPLIES.DESCRIPTION')}</p>

                <h6>{t('CART.UNANSWERED_REPLIES.CURRENT_ITEM')}</h6>
                <Table striped hover className="cart-listing tt-listing mb-4">
                  <tbody>
                    <CartListingRow item={firstItemWithUnsubmittedReplies} />
                  </tbody>
                </Table>

                <CatalogForm reply={firstUnsubmittedReply} key={firstUnsubmittedReply.id} />
              </ModalBody>
              {repliesCount !== 1 && (
                <Progress
                  className="flex-shrink-0"
                  animated={isSubmitting}
                  value={firstUnsubmittedReplyIndex + 1}
                  max={repliesCount}
                >
                  {t('CART.X_OF_Y', { x: firstUnsubmittedReplyIndex + 1, y: repliesCount })}
                </Progress>
              )}
              <ModalFooter className={classNames('sticky-bottom when-valid', { 'is-valid': isValid })}>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  {t('CART.UNANSWERED_REPLIES.CTA')}
                  {isSubmitting && <Spinner size="sm" className="ml-2" />}
                </Button>
              </ModalFooter>
            </>
          )}
        </Form>
      </FormProvider>
    </Modal>
  );
};

const mapDispatchToProps = {
  updateCartItemReplies,
};

function mapStateToProps(state: AppState) {
  const { cart } = state.Cart;
  const items = cart?.items ? Object.values(cart.items) : [];
  const hasUnsubmittedReplies = items.some((item) => item.replies.some((reply) => !reply.submitted));

  return {
    items,
    hasUnsubmittedReplies,
  };
}

export default React.memo(ProductFormModal);
