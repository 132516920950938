import React from 'react';

import { Article } from '@ttstr/api/products';
import { productDetailLink } from '@ttstr/utils';

type ProductDetailLinkGenerator = (product: Article, { canonicalUrl }?: { canonicalUrl?: string }) => string;

interface OwnProps {
  generator?: ProductDetailLinkGenerator;
}

type Props = Readonly<React.PropsWithChildren<OwnProps>>;

export const ProductDetailLinkContext = React.createContext<ProductDetailLinkGenerator>(productDetailLink);

export const { Consumer: ProductDetailLinkConsumer } = ProductDetailLinkContext;

const ProductDetailsProviderComponent: React.FC<Props> = ({ generator, children }) => {
  return <ProductDetailLinkContext.Provider value={generator}>{children}</ProductDetailLinkContext.Provider>;
};

export const ProductDetailLinkProvider = React.memo(ProductDetailsProviderComponent);

export const useProductDetailLink = () => {
  const generator = React.useContext(ProductDetailLinkContext);
  return generator ?? productDetailLink;
};
