import React from 'react';

import memoize from 'lodash.memoize';

import { AppState } from '@ttstr/reducers';
import { useShallowEqualSelector } from '@ttstr/utils';
import IntlContext, { useIntl } from './IntlContext';

interface OwnProps {
  value: number;
}

type Props = Readonly<OwnProps>;

const defaultOptions: Intl.NumberFormatOptions = {
  style: 'currency',
  currency: 'EUR',
  currencyDisplay: 'symbol',
};

export const getFormatter = memoize((language: string, currency?: string) => {
  if (currency) {
    return new Intl.NumberFormat(language, { ...defaultOptions, currency });
  } else {
    return new Intl.NumberFormat(language, defaultOptions);
  }
});

export const useCurrency = () => {
  const { language } = React.useContext(IntlContext);
  const { currency } = useShallowEqualSelector(mapStateToProps);

  return getFormatter(language, currency);
};

const Currency: React.FC<Props> = ({ value }) => {
  const { language } = useIntl();

  return <span>{getFormatter(language).format(value)}</span>;
};

const mapStateToProps = (state: AppState) => {
  const { settings } = state.Settings;
  return {
    currency: settings.config_currency,
  };
};

export default React.memo(Currency);
