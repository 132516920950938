import React from 'react';
import dot from 'dot-object';
import { Helmet } from 'react-helmet';

import { AppState } from '@ttstr/reducers';
import { Product, Variant } from '@ttstr/api/products';
import { useProductDetailLink, useProductDetails, useShopConfig } from '@ttstr/components';
import { useShallowEqualSelector } from '@ttstr/utils';

interface Props {
  product: Product;
}

/**
 * Check for Google guidelines
 * https://developers.google.com/search/docs/advanced/structured-data/product#product
 */

interface OffersSchema {
  '@type'?: 'Offer' | 'AggregateOffer';
  price?: number;
  priceCurrency?: string;
  lowPrice?: number;
  highPrice?: number;
  offerCount?: number;
  url?: string;
  availability:
    | 'https://schema.org/InStock'
    | 'https://schema.org/SoldOut'
    | 'https://schema.org/BackOrder'
    | 'https://schema.org/LimitedAvailability'
    | 'https://schema.org/OutOfStock'
    | 'https://schema.org/PreOrder'
    | 'https://schema.org/PreSale'
    | 'https://schema.org/Discontinued';
  priceValidUntil?: string;
  shippingDetails: {
    '@type': 'OfferShippingDetails';
    shippingSettingsLink: string;
  };
}

interface BrandSchema {
  '@type': string;
  name?: string;
}

interface ProductSchema {
  '@context': 'https://schema.org';
  '@type': 'Product';
  name: string;
  description: string;
  sku: string;
  // mpn?: string;
  image: string | string[];
  brand?: BrandSchema | BrandSchema[];
  offers: OffersSchema;
}

const ProductMarkup: React.FC<Props> = ({ product }) => {
  const { canonicalUrl } = useShopConfig();
  const productDetailLink = useProductDetailLink();
  const { currency } = useShallowEqualSelector(mapStateToProps);
  const { brands } = useProductDetails();

  const images = React.useMemo(() => [product.hero_image.url, ...product.gallery_image_urls.map((i) => i.original)], [
    product,
  ]);

  const jsonLd: Partial<ProductSchema> = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    brand: {
      '@type': 'Brand',
    },
    offers: {
      availability: 'https://schema.org/InStock',
      shippingDetails: {
        '@type': 'OfferShippingDetails',
        shippingSettingsLink: 'https://tickettoaster.de/retouren',
      },
    },
  };

  // Product Attributes
  dot.copy('title', 'name', product, jsonLd);
  dot.copy('description', 'description', product, jsonLd);
  dot.copy('id', 'sku', product, jsonLd);

  // Images
  jsonLd.image = images.length ? images.filter((i) => i !== null).map((imageUrl) => canonicalUrl + imageUrl) : [];

  // Brand Attributes
  if (!Array.isArray(product.brand_ids)) dot.copy('title', 'brand.name', brands[product.brand_ids], jsonLd);
  else {
    const productBrands = product.brand_ids.map((id) => {
      return {
        '@type': 'Brand',
        name: brands[id]?.title,
      };
    });
    jsonLd.brand = productBrands;
  }

  // Offer Attributes
  const variants: Variant[] = React.useMemo(() => {
    if (Array.isArray(product.online_variants_attributes)) return product.online_variants_attributes;
    return Object.values(product.online_variants_attributes);
  }, [product]);
  const prices = React.useMemo(() => variants.map((v) => v.total_price ?? v.price), [variants]);
  const lowestPrice = React.useMemo(() => Math.min(...prices), [prices]);
  const highestPrice = React.useMemo(() => Math.max(...prices), [prices]);
  const hasPriceRange = !!prices.length && lowestPrice !== highestPrice;

  if (!hasPriceRange) {
    // type Offer
    jsonLd.offers['@type'] = 'Offer';
    dot.copy('min_price', 'offers.price', product, jsonLd);
    dot.copy('valid_end_on', 'offers.priceValidUntil', product, jsonLd);
    jsonLd.offers.url = productDetailLink(product, { canonicalUrl });
  } else {
    // type AggregateOffer
    jsonLd.offers['@type'] = 'AggregateOffer';
    jsonLd.offers.lowPrice = lowestPrice;
    jsonLd.offers.highPrice = highestPrice;
    jsonLd.offers.offerCount = prices.length;
  }
  jsonLd.offers.priceCurrency = currency ?? 'EUR';

  // Availability
  if (product.status !== 'active') jsonLd.offers.availability = 'https://schema.org/OutOfStock';
  if (product.available_end_at && product.available_end_at <= new Date())
    {jsonLd.offers.availability = 'https://schema.org/SoldOut';}
  if (product.sold_out_status === 'cancelled') jsonLd.offers.availability = 'https://schema.org/Discontinued';
  if (product.sold_out_status === 'rescheduled') jsonLd.offers.availability = 'https://schema.org/BackOrder';
  if (product.sold_out_status === 'starts_soon') jsonLd.offers.availability = 'https://schema.org/PreOrder';
  if (!variants.length || product.sold_out_status === 'sold_out')
    {jsonLd.offers.availability = 'https://schema.org/SoldOut';}

  if (variants.length === 1) {
    if (variants[0].stock_status === 2) jsonLd.offers.availability = 'https://schema.org/LimitedAvailability';
    if (variants[0].stock_status === 3) jsonLd.offers.availability = 'https://schema.org/BackOrder';
  }

  return (
    <Helmet>
      <title>
        {product.supertitle && product.supertitle + ' - '}
        {product.title}
        {product.subtitle && ' - ' + product.subtitle}
      </title>

      <meta property="og:title" content={product.title} />
      <meta property="og:description" content={product.description} />
      <meta property="og:url" content={productDetailLink(product, { canonicalUrl })} />
      <meta property="og:image" content={canonicalUrl + product.image.url} />
      
      <script type="application/ld+json">{`${JSON.stringify(jsonLd)}`}</script>
      <link rel="canonical" href={productDetailLink(product, { canonicalUrl })} />
    </Helmet>
  );
};

const mapStateToProps = (state: AppState) => {
  const { settings } = state.Settings;
  return {
    currency: settings.config_currency,
  };
};

export default React.memo(ProductMarkup);
