import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Container, ContentrArea, useIntl, Masonry2, SimpleCard } from '@ttstr/components';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import { Category } from '@ttstr/api';
import { requestCategories } from '@ttstr/actions';
import { AppState } from '@ttstr/reducers';

type Props = {
  categoryFilter?: (c: Category) => any;
};

const CategoriesView: React.FC<Props> = ({ categoryFilter }) => {
  const { t } = useTranslation();
  const { categories, categoriesReceived } = useShallowEqualSelector(mapStateToProps);
  const { requestCategories } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    if (!categoriesReceived) requestCategories();
  }, [language]);

  return (
    <article className="pb-5 category-listing">
      <Helmet>
        <title>{t(`CATEGORYLISTING.TITLE`)}</title>
        <body className="category-listing-page" />
      </Helmet>

      <h1 className="text-center category-title py-5">{t(`CATEGORYLISTING.TITLE`)}</h1>

      <Container className="list-page mt-5">
        <ContentrArea id="before-listing" />

        <Masonry2>
          {Object.values(categories)
            // no filtering needed. categories turned off in BE don't get passed to API
            .sort((a, b) => a.position - b.position) // sort ascending
            .map((category) => (
              <SimpleCard
                key={category.id}
                id={category.id}
                className="cell"
                baseURLofDetailSite="/category"
                title={category.title}
                imageUrl={category.image.productthumb.url}
              />
            ))}
        </Masonry2>

        <ContentrArea id="after-listing" />
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { categories, categoriesReceived } = state.Categories;
  return {
    categories,
    categoriesReceived,
  };
};

const mapDispatchToProps = {
  requestCategories,
};

export default CategoriesView;
