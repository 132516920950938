import classNames from 'classnames';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, NavLinkProps } from 'react-router-dom';

import Badge from 'reactstrap/lib/Badge';
import Spinner from 'reactstrap/lib/Spinner';

import { loadCart, stopCartPolling } from '@ttstr/actions/cart';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

import useBounceAnimation from '@ttstr/components/Animations/useBounceAnimation';
import Countdown from '../Intl/Countdown';

const stopWatch = <i className="fal fa-clock" />;
const defaultCartIcon = <i className="fal fa-shopping-cart" />;

interface OwnProps extends Omit<NavLinkProps, 'to' | 'exact' | 'title'> {
  cartIcon?: React.ReactNode;
  compact?: boolean;
}

type Props = Readonly<OwnProps>;

const SimpleCart: React.FC<Props> = ({ className, cartIcon = defaultCartIcon, compact, ...props }) => {
  const { t } = useTranslation();
  const { cartReceived, isLocked, items, expiresIn, loading } = useShallowEqualSelector(mapStateToProps);
  const { loadCart, stopCartPolling } = useActions(mapDispatchToProps);
  const [style, trigger] = useBounceAnimation();

  React.useEffect(() => {
    if (!cartReceived) loadCart();
    return () => stopCartPolling();
  }, []);

  React.useEffect(() => {
    trigger();
  }, [items]);

  return (
    <NavLink
      {...props}
      exact
      to="/cart"
      className={classNames('nav-link', { compact }, className)}
      title={t(`NAVIGATION.TOGGLE_CART`)}
    >
      <span className="simple-cart">
        {Boolean(items) && (
          <Badge tag="span" color="info" className="item-count" style={style} pill title={t(`CART.ITEM_COUNT`)}>
            {items}
          </Badge>
        )}
        <span className="icon" style={{ position: 'relative' }}>
          <Spinner color="info" size="sm" className={classNames({ invisible: !isLocked })} />
          {cartIcon}
        </span>
        <span className="sr-only">{t(`NAVIGATION.CART`)}</span>
        {expiresIn !== null && expiresIn > 0 && Boolean(items) ? (
          <Badge
            tag="span"
            className="expiration"
            pill
            color={mapExpirationToColor(expiresIn)}
            title={t(`CART.EXPIRES_IN`)}
          >
            {stopWatch} <Countdown value={expiresIn} trim />
          </Badge>
        ) : null}
      </span>
    </NavLink>
  );
};

const THREE_MINUTES = 60 * 3;
const SEVEN_MINUTES = 60 * 7;

function mapExpirationToColor(expiration: number) {
  if (expiration > SEVEN_MINUTES) return 'secondary';
  if (expiration > THREE_MINUTES) return 'warning';
  return 'danger';
}

function mapStateToProps(state: AppState) {
  const { cart, cartReceived, loading, expiresIn } = state.Cart;
  const isLocked = Boolean(cart?.cart_token);
  const items = cart?.items ? Object.values(cart.items) : [];
  const itemSum = items.reduce((sum, item) => (item.variant.is_fee ? sum : sum + item.quantity), 0);

  return {
    items: itemSum,
    isLocked,
    cartReceived,
    expiresIn,
    loading,
  };
}

const mapDispatchToProps = {
  loadCart,
  stopCartPolling,
};

export default React.memo(SimpleCart);
