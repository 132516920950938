/**
 * Information that an extra tnc for this product exists.
 * Shows a Link to the full tnc.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppState } from '@ttstr/reducers';
import { receiveTnc } from '@ttstr/actions';

import { useActions, useShallowEqualSelector } from '@ttstr/utils';

interface Props {
  extraTncIds: number | number[];
}

const ExtraTncInfo: React.FC<Props> = ({ extraTncIds }) => {
  const { t } = useTranslation();
  const ids: number[] = Array.isArray(extraTncIds) ? extraTncIds : Array(extraTncIds);
  const { tncs } = useShallowEqualSelector(mapStateToProps);
  const { receiveTnc } = useActions(mapDispatchToProps);

  React.useEffect(() => {
    ids.forEach((id) => {
      if (!(id in tncs)) {
        receiveTnc(id);
      }
    });
  }, []);

  return (
    <aside className="extra-tnc">
      <h2 className="product-title">{t(`PRODUCT.EXTRA_TNC.TITLE`)}</h2>
      <nav>
        <ul className="list-unstyled">
          {ids.map((tncId) => (
            <li key={tncId} className="extra-tnc-link">
              {/* TODO: add fallback title */}
              <Link to={`/agb/${tncId}`} target="_blank">
                {tncs[tncId]?.title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
};

const mapStateToProps = (state: AppState) => {
  const tncs = state.Catalog.extra_tncs;
  return {
    tncs,
  };
};

const mapDispatchToProps = {
  receiveTnc,
};

export default React.memo(ExtraTncInfo);
