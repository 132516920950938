import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { receiveProducts } from '@ttstr/actions';
import { Container, LoadingSpinner, Masonry, useIntl } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import { Article } from '@ttstr/api';

type Props = {
  /**
   * productFilter can be used if you want a Route to a dedicated page for special merch/products.
   *
   * Example:
   * You want a page only with merch that has the tag id 1234.
   * The function for productFilter would then be
   *  p => p.tag_ids.includes(1234)
   *
   * Then create a route in App.tsx with
   * <Route exact path="/specialmerch" render={()=> <ProductListing productFilter={p => p.tag_ids.includes(1234)} />} />
   */
  productFilter?: (p: Article) => any;
  showHeading?: boolean;
  showLocation?: boolean;
  showCity?: boolean;
  showTitle?: boolean;
  showSupertitle?: boolean;
  showSubtitle?: boolean;
  showBrand?: boolean;
  showCategories?: boolean;
  showTags?: boolean;
};

const ProductsView: React.FC<Props> = ({
  productFilter = (t) => t,
  showHeading = false,
  showLocation = false,
  showCity = false,
  showTitle = false,
  showSupertitle = false,
  showSubtitle = false,
  showBrand = false,
  showCategories = false,
  showTags = false,
}) => {
  const { t } = useTranslation();
  const { loading, products } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveProducts();
  }, [language]);

  return (
    <article className="py-5">
      <Container className="list-page">
        <Helmet>
          <title>{t(`PRODUCTS.TITLE`)}</title>
        </Helmet>

        {showHeading && <h1 className="text-center mb-4">{t(`PRODUCTS.TITLE`)}</h1>}

        <Masonry
          id="product-listing"
          products={productFilter ? products.filter(productFilter) : products}
          type="list"
          // filters="off"
          showLocation={showLocation}
          showCity={showCity}
          showTitle={showTitle}
          showSupertitle={showSupertitle}
          showSubtitle={showSubtitle}
          showBrand={showBrand}
          showCategories={showCategories}
          showTags={showTags}
        />

        {loading && <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(ProductsView);
