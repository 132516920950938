import classNames from 'classnames';

import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'reactstrap/lib/Button';
import FormGroup from 'reactstrap/lib/FormGroup';
import Table from 'reactstrap/lib/Table';

import { CartItem, Coupon, VatRates, ShippingMethods } from '@ttstr/api';
import { AppState } from '@ttstr/reducers';
import { useShallowEqualSelector } from '@ttstr/utils';

import Alert from 'reactstrap/lib/Alert';
import Currency from '../Intl/Currency';
import NumberComponent from '../Intl/NumberComponent';
import Percent from '../Intl/Percent';
import LoadingSpinner from '../Loading/LoadingSpinner';
import CartListingRow from './CartListingRow';
import CouponComponent from './Coupon';

interface OwnProps {
  dark?: boolean;
  readOnly?: boolean;
  className?: string;
}
interface StateProps {
  cartReceived: boolean;
  availableShippingMethods: ShippingMethods;
  items: CartItem[];
  isLocked: boolean;
  coupons: Coupon[];
  totalValue: number;
  vatRates: VatRates;
  loading: boolean;
  cartMessage: string;
}

type Props = Readonly<OwnProps>;

const CartListing: React.FC<Props> = ({ dark, readOnly, className }) => {
  const { t } = useTranslation();
  const {
    coupons,
    availableShippingMethods,
    items,
    isLocked,
    totalValue,
    vatRates,
    loading,
    cartReceived,
    cartMessage,
  } = useShallowEqualSelector(mapStateToProps);

  if (!cartReceived && loading) {
    return <LoadingSpinner label={t(`LOADING.CART`)} />;
  }

  if (!items.length) {
    return (
      <div className="text-left">
        <p>{t('CART.NO_ITEMS')}</p>
        <FormGroup className="d-none goto-item-listing--button-wrapper">
          <Button tag={Link} to="/" color="primary">
            {t(`CART.GOTO_ITEM_LISTING`)}
          </Button>
        </FormGroup>
      </div>
    );
  }

  return (
    <>
      {cartMessage && (
        <Alert id="cart-message" color="info" className="mb-2">
          {cartMessage}
        </Alert>
      )}
      <Table striped hover className={classNames('cart-listing tt-listing mb-3', className)} dark={dark}>
        <thead>
          <tr>
            <th scope="col">{t('CART.ITEMS')}</th>
            <th className="text-center" scope="col">
              <span className="sr-only">{t(`CART.MODIFY`)}</span>
            </th>
            <th className="text-right" scope="col">
              {t('CART.BREAKDOWN')}
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <CartListingRow key={item.id} item={item} readOnly={readOnly || isLocked} />
          ))}
          {coupons.map((item) => (
            <tr key={item.token} className="table-primary">
              <td colSpan={2}>
                <span className="h6">
                  {/* eslint-disable-next-line react/jsx-no-literals */}
                  <NumberComponent value={1} /> &times; {item.title}
                </span>
              </td>
              <td className="text-right">
                <Currency value={item.value} />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={2} className="text-right" scope="row">
              {t(`CART.SUM`)}
            </th>
            <td className="text-right">
              <strong>
                <Currency value={totalValue} />
              </strong>
            </td>
          </tr>
          {Object.entries(vatRates).map(([rate, value]) => (
            <tr key={rate} className="small text-muted">
              <td colSpan={2} className="text-right">
                {t('CART.VAT')} <Percent value={Number(rate) / 100} />
              </td>
              <td className="text-right">
                <Currency value={Number(value)} />
              </td>
            </tr>
          ))}
        </tfoot>
      </Table>
      {!Boolean(availableShippingMethods.length) && (
        <Alert color="danger">
          <div dangerouslySetInnerHTML={{ __html: t('CART.NO_AVAILABLE_SHIPPING_METHOD') }} />
        </Alert>
      )}
      {isLocked ? <LoadingSpinner label={t(`CART.CONVERSION_IN_PROGRESS`)} /> : <CouponComponent />}
    </>
  );
};

function mapStateToProps(state: AppState): StateProps {
  const { cart, loading, cartReceived } = state.Cart;
  const items = cart?.items ? Object.values(cart.items) : [];
  const isLocked = Boolean(cart?.cart_token);
  const coupons = cart?.coupons ?? [];
  const totalValue = cart?.price_after_coupons ?? 0;
  const vatRates = cart?.vat_rates ?? {};
  const availableShippingMethods = cart?.available_shipping_methods ?? [];
  const cartMessage = cart?.message;

  return {
    cartReceived,
    loading,
    availableShippingMethods,
    items,
    isLocked,
    coupons,
    totalValue,
    vatRates,
    cartMessage,
  };
}

export default React.memo(CartListing);
